import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import { useNavigate } from 'react-router-dom'

export default function Layout() {
  const navigate = useNavigate()
  function handleClose() {
    if (
      window.location.pathname === "/auth/signup" ||
      window.location.pathname === "/auth/login" ||
      window.location.pathname === "/auth/signup/guest"
    ) {
      navigate("/")
    }
    return
  }
  return (
    <>
      <Header />
      <div className='start--main' onClick={handleClose}>
        <Outlet />
      </div>
    </>
  )
}
