import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { database } from '../../firebase'

export default function GameRoute({ children }) {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const location = useLocation()
    const gameID = location.state?.id
    const local = location.state?.local
    const requestID = location.state?.requestID
    const opponent = location.state?.opponent

    async function getGameData() {
        if (gameID) {

            const game = await database.games.doc(gameID).get().then(doc => doc.data())
            const playerRound = game.player1Id === currentUser.uid ? game.player1Round : game.player2Round
            if (game.playerTurn !== currentUser.uid) return navigate('/dashboard')
            if (game !== undefined) {
                if(game.player1Round === game.player2Round && game.player1Round > game.rounds.length && game.playerTurn === currentUser.uid && game.gameStarted === true) {
                    return
                }
                else if (playerRound > game.rounds.length && game.playerTurn === currentUser.uid && game.gameStarted === true) {
                    return navigate('/choose-category', { state: { id: gameID, local: local, requestID: requestID, opponent } })
                }
                else if (playerRound >= game.rounds.length) {
                    return
                }
                else {
                    navigate('/dashboard')
                }
            }
        }
        else if (local) {
            return
        }
        else if (requestID) {
            return
        }
        else return navigate('/dashboard')
    }
    React.useEffect(() => {
        getGameData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])
    return children
}
