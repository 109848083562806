import React, { useContext, useEffect, useState } from 'react'
import { auth, database } from '../firebase'

const AuthContext = React.createContext()

function getSavedValue(key, initialValue) {
    const savedValue = JSON.parse(localStorage.getItem(key))
    if(savedValue) return savedValue

    if(initialValue instanceof Function) return initialValue()
    return initialValue
}

export function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
        return getSavedValue(key, initialValue)
    })
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue))
        } catch (error) {
            // console.log(error)
        }
    }, [key, storedValue])

    return [storedValue, setStoredValue]
}

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [loggedIn, setLoggedIn] = useState(false)
    const [userParams, setUserParams] = useState({})

    async function signup(email, password, displayName, guest) {
        await database.users.where("displayName", "==", displayName.toLowerCase()).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return auth.createUserWithEmailAndPassword(email, password)
                        .then(user => {
                            database.users.doc(user.user.uid).set({
                                email: email,
                                displayName: displayName,
                                displayNameLower: displayName.toLowerCase(),
                                guest: guest,
                                FCMToken: [],
                            })
                        })
                        .catch (error => {
                            if (error === "FirebaseError: Firebase: The email address is already in use by another account. (auth/email-already-in-use).") {
                                throw new Error("Email already in use")
                            }
                            throw error
                        })
                }
                else {
                    throw new Error("Display name already exists")
                }
            }
            )
            .catch(error => {
                if (error === "FirebaseError: Firebase: The email address is already in use by another account. (auth/email-already-in-use).") {
                    throw new Error("Email already in use")
                }
                throw error
            })
    }

    async function login(email, password) {
        await auth.signInWithEmailAndPassword(email, password)
            .catch(error => {
                throw error
            }
            )
    }

    function logout() {
        return auth.signOut(currentUser)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            if (user) {
                database.users.doc(user.uid).get().then(doc => {
                    setUserParams({
                        displayName: doc.data().displayName,
                        email: user.email,
                    })
                })
            }
            setLoggedIn(!!user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        loggedIn,
        signup,
        login,
        logout,
        userParams,
    }
    return (
        <AuthContext.Provider value={value}>
            {loading || children}
        </AuthContext.Provider>
    )
}
