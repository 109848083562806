import React, { useState, useEffect, useContext } from 'react'
import { database } from '../../firebase'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckMark } from '../../img/check-solid.svg'
import { ReactComponent as CrossMark } from '../../img/xmark-solid.svg'
import { FormatContext } from '../../context/FormatContext'

export default function ActiveGames() {
    const { currentUser, userParams } = useAuth()
    // eslint-disable-next-line no-unused-vars
    const { theme } = useContext(FormatContext)
    const [currentGames, setCurrentGames] = useState([])
    const [currentRequests, setCurrentRequests] = useState([])
    const [currentRandomRequests, setCurrentRandomRequests] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {

        function unsubscribe() {
            database.games
                .onSnapshot(snapshot => {
                    const games = []
                    snapshot.forEach(doc => {
                        games.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    })
                    setCurrentGames(games)
                })
        }
        return unsubscribe()
    }, [])

    useEffect(() => {

        function unsubscribe() {
            database.requests
                .onSnapshot(snapshot => {
                    const requests = []
                    setCurrentRandomRequests(false)
                    snapshot.forEach(doc => {
                        if (doc.data().requested_id === currentUser.uid && doc.data().randomGame === false) {
                            requests.push({
                                id: doc.id,
                                requested_email: doc.data().requested_email,
                                requester_email: doc.data().requester_email,
                                requester_name: doc.data().requester_name,
                                requested_id: doc.data().requested_id,
                            })
                        }
                        else if (doc.data().requester_id === currentUser.uid && doc.data().randomRequest === true) {
                            setCurrentRandomRequests(true)
                        }
                        else return
                    })
                    setCurrentRequests(requests)
                })
        }
        return unsubscribe()
    }, [currentUser.uid])

    const gameItemsStyles = (game) => {

        const player1Playing = game.data.player1Id === currentUser.uid ? true : false

        const opponent = player1Playing ? game.data.player2Name : game.data.player1Name
        const currentPlayerRound = player1Playing ? game.data.player1Round : game.data.player2Round

        if ((game.data.player1Won === false && game.data.player2Won === false) && game.data.playerTurn === currentUser.uid) {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "column" }} className="blackText">
                        <h1>{opponent}</h1>
                        <div className="stats--container">
                            <h3 id='round'><strong>Round: </strong>{currentPlayerRound}</h3>
                            <h3 id='score'><strong>Score: </strong>{game.data.player1Score} / {game.data.player2Score}</h3>
                        </div>
                    </div>
                    <h1 className='your--turn blackText'>Your turn!</h1>
                </>
            )
        }
        if (game.data.player1Won === true && game.data.player2Won === true) {
            return <h1>Draw versus {opponent}!</h1>
        }
        else if ((game.data.player1Won === true && game.data.player1Id === currentUser.uid) || (game.data.player2Won === true && game.data.player2Id === currentUser.uid)) {
            return <h1>You won against {opponent}!</h1>
        }
        else if (game.data.player1Won === true && game.data.player2Id === currentUser.uid) {
            return <h1>{game.data.player1Name} won!</h1>
        }
        else if (game.data.player2Won === true && game.data.player1Id === currentUser.uid) {
            return <h1>{game.data.player2Name} won!</h1>
        }
    }

    const gameItems = currentGames.map(game => {
        const index = game.data.rounds.length - 1
        if (game.data.playerTurn === currentUser.uid && (game.data.player1Won === false && game.data.player2Won === false)) {
            return (
                <div key={`gameValue ${game.id}`} className="games--container blackText noSelect" onClick={() => inspectGame(game.id, game.data.player1Id, game.data.player1Name, game.data.player2Name, game.data.rounds[index]?.category)}>
                    {gameItemsStyles(game)}
                </div>
            )
        }
        else return undefined
    })

    const gameItemsFinished = currentGames.map(game => {
        const index = game.data.rounds.length - 1
        if ((game.data.player1Won === true || game.data.player2Won === true) && (game.data.player1Id === currentUser.uid || game.data.player2Id === currentUser.uid)) {
            return (
                <div key={`gameValue ${game.id}`} style={{ background: "#a0ffa7" }} className="games--container blackText noSelect" onClick={() => inspectGame(game.id, game.data.player1Id, game.data.player1Name, game.data.player2Name, game.data.rounds[index].category)}>
                    {gameItemsStyles(game)}
                </div>
            )
        }
        else return undefined
    }
    )

    function gameItemsStylesTheirTurn(game) {
        if (game.data.playerTurn !== currentUser.uid && (game.data.player1Won === false && game.data.player2Won === false)) {
            return (
                <>
                    <h1 className='blackText'>{game.data.player1Id === currentUser.uid ? game.data.player2Name : game.data.player1Name} playing round {game.data.player1Id === currentUser.uid ? game.data.player2Round : game.data.player1Round} out of 6</h1>
                    <h1 className='your--turn blackText' style={{ opacity: "0.5" }}>Their turn</h1>
                </>
            )
        }
        else return undefined
    }

    const gameItemsTheirTurn = currentGames.map(game => {
        const index = game.data.rounds.length - 1
        if (((game.data.player1Id === currentUser.uid && game.data.playerTurn !== currentUser.uid) || (game.data.player2Id === currentUser.uid && game.data.playerTurn !== currentUser.uid)) && (game.data.player1Won === false && game.data.player2Won === false)) {
            return (
                <div key={`gameValue ${game.id}`} className="games--container games--container--their--turn noSelect" onClick={() => inspectGame(game.id, game.data.player1Id, game.data.player1Name, game.data.player2Name, game.data.rounds[index]?.category)}>
                    {gameItemsStylesTheirTurn(game)}
                </div>
            )
        }
        else return undefined
    }
    )

    async function inspectGame(id, player1Id, player1Name, player2Name, category) {
        // setKey("opponent")
        let opponent
        if (player1Id === currentUser.uid) {
            opponent = player2Name
        }
        else {
            opponent = player1Name
        }
        navigate('/inspectgame', { state: { id, opponent, category } })
    }

    async function acceptRequest(requestID, opponent) {
        navigate("/choose-category", { state: { requestID, opponent } })
    }


    function rejectRequest(e, id) {
        e.preventDefault()
        database.requests.doc(id).delete()
    }

    function requestItems() {

        if (currentRequests) {
            const requestItems = currentRequests.map(request => {
                return (
                    <div key={`requestValue ${request.id}`} className="game--request">
                        <h3>{request.requester_name} wants to play!</h3>
                        <div className="game--request--buttons">
                            <button id="accept--button" onClick={(e) => acceptRequest(request.id, request.requester_name)}><CheckMark className='check--cross--marks' /></button>
                            <button id="reject--button" onClick={(e) => rejectRequest(e, request.id)}><CrossMark className='check--cross--marks' /></button>
                        </div>
                    </div>
                )
            }
            )
            return requestItems
        }
        else return undefined
    }

    const requests = requestItems()

    function isDefined(x) {
        return x !== undefined && x !== null;
    }
    
    return (
        <>
            <div className="active--games--container">
                <div className='start--new--game--outer'>
                    <div className="start--new--game blackText noSelect" onClick={() => navigate('new-game')}>
                        <h1>Start a new game</h1>
                    </div>
                </div>
                <div className='currentGames--container'>
                    {currentRandomRequests &&
                        <div className="randomRequest">
                            Searching for games
                            <div className='loading--container'>
                                <div className='loading--dot' id='loading--one'></div>
                                <div className='loading--dot' id='loading--two'></div>
                                <div className='loading--dot' id='loading--three'></div>
                            </div>
                        </div>
                    }
                    {requests &&
                        <div className="game--requests">
                            {requests}
                        </div>
                    }
                    {gameItems.some(isDefined) &&
                        <div className="game--items">
                            {gameItems}
                        </div>
                    }
                    {gameItemsTheirTurn.some(isDefined) &&
                        <div className="game--items--their--turn">
                            {gameItemsTheirTurn}
                        </div>
                    }
                    {gameItemsFinished.some(isDefined) &&
                        <div className="game--items--finished">
                            {gameItemsFinished}
                        </div>
                    }
                    {!gameItems.some(isDefined) && !gameItemsTheirTurn.some(isDefined) && !requests.some(isDefined) &&
                        <div style={{ marginTop: "50px", color: theme === "dark" ? "#cacaca" : "#2f2f2f", textAlign: "center" }}>
                            <h1>Welcome to .quiz, <span style={{ color: "rgb(193, 127, 1)" }}>{userParams.displayName}</span></h1>
                            <h3>Start a new game to play!</h3>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
