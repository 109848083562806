import React, { useContext, useEffect, useState } from 'react'
import { useLocalStorage } from '../context/AuthContext.js'
import { FormatContext } from '../context/FormatContext.js'
import { useNavigate } from 'react-router-dom'


export default function Header() {

    // const { format } = useContext(FormatContext)
    // const { category } = useContext(CategoryContext)
    const [switchTheme, setSwitchTheme] = useState({})
    const [noClick, setNoClick] = useState(false)
    //eslint-disable-next-line no-unused-vars
    const [themes, setThemes] = useLocalStorage('theme', 'light')
    const { theme, setTheme } = useContext(FormatContext)
    const navigate = useNavigate()

    

    useEffect(() => {
        if(theme === 'dark') {
            document.getElementById("header").style.backgroundColor = 'rgb(35, 35, 39)'
        }
        else {
            document.getElementById("header").style.backgroundColor = 'rgb(254, 255, 248)'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if(theme === 'dark' && initialStyle === false) {
    //         document.body.style.setProperty('--body-animation', 'lightToDarkBody 0.2s ease-in-out forwards')
    //         document.body.style.setProperty('--body-filter', 'none')
    //         document.getElementById("header").style.animation = 'darkToLightRoot 0.2s ease-in-out forwards'
    //         document.getElementById("root").style.animation = 'darkToLightRoot 0.2s ease-in-out forwards'
    //     }
    //     else if (theme === 'light' && initialStyle === false){
    //         document.body.style.setProperty('--body-animation', 'darkToLightBody 0.2s ease-in-out forwards')
    //         document.body.style.setProperty('--body-filter', 'none')
    //         document.getElementById("header").style.animation = 'lightToDarkRoot 0.2s ease-in-out forwards'
    //         document.getElementById("root").style.animation = 'lightToDarkRoot 0.2s ease-in-out forwards'
    //     }
    //     setInitialStyle(false)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [themes])

    return (
        <div className='header' id='header'>
                {/* {format === 0 && <h1 className={theme === 'dark' ? "light--text start--title short" : "start--title short"} id={category.short ? 'fadeTitle' : ''}>{category.short}</h1>} */}
                <div className='header--container'>
                <p className={theme === 'dark' ? 'light--text logo noSelect' : 'logo noSelect'} onClick={() => navigate("/")}>.quiz</p>
            <div className={`theme--switch theme--switch--${theme}`}
                onClick={(e) => {
                    e.stopPropagation()
                    noClick || setThemes(theme === 'light' ? 'dark' : 'light'); noClick || setTheme(theme === 'light' ? 'dark' : 'light')
                    if (theme === 'light' && noClick === false)
                        setSwitchTheme(({
                            outer:
                            {
                                animationName: 'lightToDark',
                                animationDuration: '0.3s',
                                animationTimingFunction: 'ease-in-out',
                                animationFillMode: 'forwards'
                            },
                            inner:
                            {
                                animationName: 'dotLeftToRight',
                                animationDuration: '0.3s',
                                animationTimingFunction: 'ease-in-out',
                                animationFillMode: 'forwards'
                            }
                        }))
                    else
                        noClick || setSwitchTheme(({
                            outer:
                            {
                                animationName: 'darkToLight',
                                animationDuration: '0.3s',
                                animationTimingFunction: 'ease-in-out',
                                animationFillMode: 'forwards'
                            },
                            inner:
                            {
                                animationName: 'dotRightToLeft',
                                animationDuration: '0.3s',
                                animationTimingFunction: 'ease-in-out',
                                animationFillMode: 'forwards'
                            }
                        }))
                }}
                style={switchTheme.outer}
                onAnimationStart={() => setNoClick(true)}
                onAnimationEnd={() => setNoClick(false)}
            >
                <div
                    className={`theme--switch--dot theme--switch--dot--${theme}`}
                    style={switchTheme.inner}
                >
                </div>
            </div>
            </div>
        </div>
    )
}
