import React from 'react'
import { useAuth } from '../context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as SignInLogo } from '../img/user-lock-solid.svg'
import { ReactComponent as LocalLogo } from '../img/angles-up-solid.svg'
import { ReactComponent as LILogo } from '../img/linkedin-in-brands.svg'
import { ReactComponent as ContactLogo } from '../img/address-card-solid.svg'
import { ReactComponent as SignOutLogo } from '../img/arrow-right-to-bracket-solid.svg'
import { ReactComponent as BackLogo } from '../img/chevron-left-solid.svg'
import { useLocalStorage } from '../context/AuthContext'
import { FormatContext } from '../context/FormatContext'
import { CategoryContext } from '../context/CategoryContext'

export default function BottomNav({ green, back, local, disabled, gameStarted, gameID, requestID }) {

    const location = useLocation()
    const id = location?.state?.id
    const opponent = location?.state?.opponent
    const { category, setCategory } = React.useContext(CategoryContext)
    const { theme } = React.useContext(FormatContext)
    const { currentUser, logout, loggedIn } = useAuth()
    const navigate = useNavigate()
    const [key, setKey] = React.useState("gameID")
    //eslint-disable-next-line no-unused-vars
    const [storedValue, setStoredValue] = useLocalStorage(key, null)
    const [opened, setOpened] = React.useState(false)
    const pathname = window.location.pathname

    function togglePopup(dont) {
        if (opened || dont) {
            setOpened(false)
            document.querySelector('.closingDiv').style.display = 'none'
            document.querySelector('.about--links--container').style.animation = 'hideAboutLinks 0.3s ease-in forwards'
        }
        else {
            document.querySelector('.closingDiv').style.display = 'block'
            document.querySelector('.about--links--container').style.display = 'flex'
            document.querySelector('.about--links--container').style.animation = 'revealAboutLinks 0.3s ease-out forwards'
            setOpened(true)
        }

    }

    React.useEffect(() => {
        async function unsubscribe() {
            setKey("gameID")
            await setStoredValue(null)
            setKey("opponent")
            await setStoredValue(null)
            setKey("category")
            await setStoredValue(null)
        }
        unsubscribe()
    }, [pathname, setStoredValue])


    function handleAnimationEnd(e) {
        if (e.animationName === 'hideAboutLinks') {
            const modalQuery = document.querySelector('.about--links--container')
            modalQuery.style.display = 'none'
        }
        return
    }

    function goToGameOnline() {
        if (!category && pathname !== '/inspectgame') {
            setCategory({
                id: 1,
                title: "All Categories",
                short: "surprise_me",
            })
        }
        navigate('/game/online', { state: { id: id, opponent, requestID, pathname } })
    }
    
    function goToGame() {
        if (!category && green) {
            setCategory({
                id: 1,
                title: "All Categories",
                short: "surprise_me",
            })
        }
        navigate('/game', { state: { local: true } })
    }

    async function resetGame() {
        // console.log('reset game')
        setKey("gameID")
        await setStoredValue(null)
        setKey("opponent")
        await setStoredValue(null)
        setKey("category")
        await setStoredValue(null)
        navigate('/choose-category')
    }

    // window.open("https://www.linkedin.com/in/pontus-thelin-440639139/", "_blank")

    return (
        <>
            <div className="closingDiv" onClick={() => togglePopup()}></div>
            <div className="about--links--container" onAnimationEnd={(e) => handleAnimationEnd(e)}>
                <div className="about--button--linkedin">
                    <a className='links--button linkedIn' href='https://www.linkedin.com/in/pontus-thelin-440639139/' rel='noreferrer' target={"_blank"} onClick={() =>  togglePopup()}>
                        <LILogo className='nav--logo' fill={"#FFFFFF"} />
                        <h1>My LinkedIn</h1>
                    </a>
                </div>
                <div className="about--buttons">
                    <div className='links--button features' onClick={() => { togglePopup(); navigate('/features') }}><h1>Features</h1></div>
                    <div className='links--button aboutme' onClick={() => { togglePopup(); navigate('/aboutme') }}><h1>About me</h1></div>
                </div>
            </div>
            <div className={theme === "dark" ? 'bottom--nav--dark' : 'bottom--nav'}>

                {
                    back ?
                        <div className='nav--button' onClick={() => navigate(loggedIn ? '/dashboard' : '/', { state: { id: null, opponent: null, requestID: null } })}><BackLogo className='nav--logo' fill={theme === "dark" ? '#cacaca' : "#2f2f2f"} /><h1>Back</h1></div>
                        :
                        <div className='nav--button' onClick={() => togglePopup()}><ContactLogo className='nav--logo' fill={theme === "dark" ? '#cacaca' : "#2f2f2f"} /><h1>About</h1></div>
                }
                <div className={(green || pathname === "/") ? 'nav--button greenPlay' : 'nav--button'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={(e) => {
                    e.stopPropagation();
                    if (disabled) return
                    gameStarted ?
                        green ? goToGameOnline() : resetGame()
                        :
                        local ?
                            green ? goToGame() :
                                navigate('/choose-category', { state: { local: true } })
                            :
                            navigate('/choose-category', { state: { id, opponent } })

                }
                }><LocalLogo className='nav--logo' fill={(green || pathname === "/") ? "#2f2f2f" : theme === "dark" ? '#cacaca' : "#2f2f2f"
                } /><h1>Play</h1>{local && <p id='local'>(offline)</p>}</div>
                {
                    currentUser ?
                        <div className='nav--button' onClick={() => { logout(); togglePopup() }}><SignOutLogo className='nav--logo' fill={theme === "dark" ? '#cacaca' : "#2f2f2f"} /><h1>Log out</h1></div>
                        :
                        <div className='nav--button' onClick={(e) => { e.stopPropagation(); navigate('/auth/login'); togglePopup(true) }}><SignInLogo className='nav--logo' fill={theme === "dark" ? '#cacaca' : "#2f2f2f"} /><h1>Log in</h1></div>
                }
            </div >
        </>

    )
}
