import { useState, useEffect } from 'react';

function getWindowDimensions() {
  let format = 0
  const { innerWidth: width, innerHeight: height } = window;
  if (width < 800) {
    format = 1
  }
  else if (width < 1000) {
    format = 2
  }
  else {
    format = 0
  }
  return {
    width,
    height,
    format
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function throttle(cb, delay) {
      let timeout = false;
      return function (...args) {
        if (timeout) return;

        cb(...args)
        timeout = true

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          timeout = false;
        }, delay);
      };
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', throttle(handleResize, 100));
    return () => window.removeEventListener('resize', throttle(handleResize, 100));
  }, []);

  return windowDimensions;
}