import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { nanoid } from 'nanoid'
import Question from './Question'
import { FormatContext } from '../context/FormatContext.js'
import { CategoryContext } from '../context/CategoryContext.js'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../context/AuthContext'
import GameHeader from './GameHeader'

export default function Game() {

    const [quiz, setQuiz] = useState()
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [generatedUrl, setGeneratedUrl] = useState('https://opentdb.com/api.php?amount=5')
    const [finishedGame, setFinishedGame] = useState(false)
    const [showCorrectAns, setShowCorrectAns] = useState(false)
    const [questionNumber, setQuestionNumber] = useState(0)
    const [questionAnswered, setQuestionAnswered] = useState(false)
    const [userSelectedBoxes, setUserSelectedBoxes] = useState([
        'box--default',
        'box--default',
        'box--default',
        'box--default',
        'box--default'
    ])
    const { format } = useContext(FormatContext)
    const { category } = useContext(CategoryContext)
    const [theme] = useLocalStorage('theme', 'light')
    const catTitle = categoryTitle()
    const allCategories = [2, 3, 13, 14, 25, 26]
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('.start--main').setAttribute('style', 'padding: 85px 0 0 0 !important');
        const unsubscribe = () => {
            document.querySelector('.start--main').setAttribute('style', 'padding: 85px 0 100px 0 !important');
        }
        return unsubscribe
    }, [])

    useEffect(() => {
        if (category.short !== undefined)
            document.title = `${category.short}.quiz`
        else
            document.title = '.quiz'
    }, [category.short])

    useEffect(() => {
        fetchQuestions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchQuestions = async () => {
        setQuiz(undefined)
        const response = await axios.request({
            method: "GET",
            url: generatedUrl,
            responseEncoding: 'binary'
        })
        const generatedQuestions = await generateQuestions(response)
        setQuiz(generatedQuestions)
        setCorrectAnswer(0)
        setFinishedGame(false)
        setShowCorrectAns(false)
        setUserSelectedBoxes([
            'box--default',
            'box--default',
            'box--default',
            'box--default',
            'box--default'
        ])
    }

    function goToMain() {
        setQuestionNumber(0)
        navigate('/')
    }

    useEffect(() => {
        if (category.url !== undefined)
            setGeneratedUrl(`https://opentdb.com/api.php?amount=5${category.url}`)
        else
            setGeneratedUrl('https://opentdb.com/api.php?amount=5')
    }, [category.url])

    function next() {
        setQuestionNumber(prev => prev + 1)
        setQuestionAnswered(false)
        if (finishedGame === true) {
            setCorrectAnswer(0)
            setQuestionNumber(0)
            fetchQuestions()
        }
        else if (questionNumber === 4) {
            setFinishedGame(true)
        }
    }

    function handleSelection(ansId, queId, i) {
        let content
        const meme = quiz.map(prev => {
            let ans = prev.answers.map(p => {
                if (ansId === p.id && p.selected === false && queId === prev.id) {
                    return {
                        ...p,
                        selected: true,
                        correct: false,
                        incorrect: false
                    }
                }
                else if (ansId !== p.id) {
                    return {
                        ...p,
                        selected: false,
                        correct: false,
                        incorrect: false
                    }
                }
                return p
            }
            )
            return { ...prev, answers: ans }
        })
        content = meme
        setQuiz(content)
        setTimeout(() => checkAnswers(content, ansId, queId, i), 1000)
    }

    function checkAnswers(content, ansId, queId, i) {
        setQuestionAnswered(true)
        const modifiedQuiz = content.map(prevQuiz => {
            let ans = prevQuiz.answers.map(p => {
                if (p.answer === htmlDecode(prevQuiz.correctAns) && p.selected === true) {
                    userSelectedBoxes[i] = 'box--correct'
                    setCorrectAnswer(prev => prev + 1)
                }
                else if (p.answer !== htmlDecode(prevQuiz.correctAns) && p.selected === true) {
                    userSelectedBoxes[i] = 'box--incorrect'
                }
                if (ansId === null) {
                    if (prevQuiz.id === queId && p.answer === htmlDecode(prevQuiz.correctAns)) {
                        return { ...p, correct: true, incorrect: false }
                    }
                    else if (prevQuiz.id === queId && p.answer !== htmlDecode(prevQuiz.correctAns)) {
                        userSelectedBoxes[i] = 'box--incorrect'
                        return { ...p, incorrect: true, correct: false, noAnswer: true }
                    }
                }
                else {
                    if (prevQuiz.id === queId && p.answer === htmlDecode(prevQuiz.correctAns)) {
                        return { ...p, correct: true, incorrect: false }
                    }
                    else if (prevQuiz.id === queId && p.answer !== htmlDecode(prevQuiz.correctAns)) {

                        return { ...p, incorrect: true, correct: false }
                    }
                }
                return p
            })
            return ans
        })

        setQuiz(prev => {
            const obj = prev.map((p, index) => ({ ...p, answers: modifiedQuiz[index] }))
            return obj
        })
    }

    const htmlDecode = (input) => {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    function generateQuestions(data) {
        if (allCategories.includes(category.id)) {
            let ques
            let populatedArray = {}
            let subCategories = []
            switch (category.id) {
                case 2:
                case 3:
                    subCategories = [20, 21, 22, 23, 24, 25, 26, 27, 28]
                    break;
                case 13:
                case 14:
                    subCategories = [10, 11, 12, 13, 14, 15, 16, 29, 31, 32]
                    break;
                case 25:
                case 26:
                    subCategories = [17, 18, 19, 30]
                    break;
                default:
                    break;
            }
            setQuiz(undefined)
            const getFive = async () => {
                let resultsArray = []
                let dataObj = {}
                for (let i = 0; i < 5; i++) {
                    const randomIndex = Math.floor(Math.random() * subCategories.length)
                    const catUrl = subCategories[randomIndex]
                    const response = await axios.request({
                        method: "GET",
                        url: `https://opentdb.com/api.php?amount=1&category=${catUrl}`,
                        responseEncoding: 'binary'
                    })
                    dataObj = response.data
                    resultsArray.push(response.data.results[0])
                    populatedArray = { ...response }
                }
                dataObj = { ...dataObj, results: resultsArray }
                populatedArray = { ...populatedArray, data: dataObj }
                ques = await generate(populatedArray)
                setQuiz(ques)
                setCorrectAnswer(0)
                setFinishedGame(false)
                setShowCorrectAns(false)
            }
            getFive()
        }
        else {
            return generate(data)
        }
    }

    function generate(data) {
        let content = data.data.results.map(result => {
            let answers = []

            const length = result.incorrect_answers.length

            if (length > 1) {
                result.incorrect_answers.map(ans => {
                    answers.push({
                        id: nanoid(),
                        answer: htmlDecode(ans),
                        selected: false,
                        correct: false,
                        incorrect: false
                    })
                    return ans
                })
                answers.splice(
                    Math.ceil(
                        Math.random() * (4) - 1), 0, {
                    id: nanoid(),
                    answer: htmlDecode(result.correct_answer),
                    selected: false,
                    correct: false,
                    incorrect: false
                })
            }
            else {

                answers.push({
                    id: nanoid(),
                    answer: htmlDecode(result.incorrect_answers[0]),
                    selected: false,
                    correct: false,
                    incorrect: false
                })

                answers.splice(
                    result.correct_answer === "True" ? 0 : 1, 0, {
                    id: nanoid(),
                    answer: htmlDecode(result.correct_answer),
                    selected: false,
                    correct: false,
                    incorrect: false
                })
            }
            return ({
                id: nanoid(),
                question: htmlDecode(result.question),
                answers: answers,
                correctAns: htmlDecode(result.correct_answer)
            })
        })
        return content
    }


    function categoryTitle() {
        if (format && format === 0) {
            if (category.title === 'All categories') {
                return "Playing all categories"
            }
            else {
                const title = category.title.charAt(0).toLowerCase() + category.title.slice(1)
                return `Playing the ${title} category.`
            }
        }
        else {
            if (category.title === 'All categories') {
                return "Playing all"
            }
            else {
                const title = category.title.charAt(0).toLowerCase() + category.title.slice(1)
                return `Playing ${title}`
            }
        }
    }

    return (
        // <div className='main--container'>
        //     {quiz === undefined ?
        //         <div>
        //             <h1 className={theme === 'dark' ? 'loading--title light--text' : 'loading--title'}>Loading questions...</h1>
        //         </div>
        //         :
        //         <>
        //             <div className='nav--title'>
        //                 <img alt='back-button' onClick={goToMain} id="back--button" src={theme === 'dark' ? require('../img/back-button-light.png') : require('../img/back-button.png')} />
        //                 <h1 className={theme === 'dark' ? 'category--title light--text' : 'category--title'}>{catTitle}</h1>
        //                 <h1 className={
        //                     theme === 'dark' ?
        //                         'start--title logo light--text noSelect'
        //                         :
        //                         'start--title logo noSelect'}
        //                 >
        //                     .quiz
        //                 </h1>

        //             </div>
        //             {quiz !== undefined && quiz.map((q, index) => (
        //                 <FormatContext.Provider value={{ format, theme }} key={`provider ${index}`}>
        //                     <Question
        //                         key={`Question ${index}`}
        //                         quiz={quiz}
        //                         questionIndex={index}
        //                         question={q.question}
        //                         answers={q.answers}
        //                         questionId={q.id}
        //                         selection={handleSelection}
        //                         checkAnswers={checkAnswers}
        //                         showCorrectAns={showCorrectAns}
        //                         correctAnswer={correctAnswer}
        //                         finishedGame={finishedGame}
        //                         questionNumber={questionNumber}
        //                         setQuestionNumber={setQuestionNumber}
        //                         questionAnswered={questionAnswered}
        //                         setQuestionAnswered={setQuestionAnswered}
        //                         next={next}
        //                         userSelectedBoxes={userSelectedBoxes}
        //                         setUserSelectedBoxes={setUserSelectedBoxes}
        //                     />
        //                 </FormatContext.Provider>
        //             ))}

        //             {(questionAnswered || finishedGame) && <div
        //                 id={theme === 'dark' ? "resetButtonDark" : "resetButton"}
        //                 className={format === 0 ? 'main--button reset--button noSelect' : 'start--button noSelect'}
        //                 onClick={() => next()}>{finishedGame ? "Reset game" : "Next question"}
        //             </div>}

        //         </>
        //     }
        // </div>
        <div className='main--container'>
            {quiz === undefined ?
                <div>
                    <h1 className={theme === 'dark' ? 'loading--title light--text' : 'loading--title'}>Loading questions...</h1>
                </div>
                :
                <>
                    <GameHeader goToMain={goToMain} catTitle={catTitle} />
                    {quiz !== undefined && quiz.map((q, index) => (
                        <FormatContext.Provider value={{ format, theme }} key={`provider ${index}`}>
                            <Question
                                key={`Question ${index}`}
                                quiz={quiz}
                                questionIndex={index}
                                question={q.question}
                                answers={q.answers}
                                questionId={q.id}
                                selection={handleSelection}
                                checkAnswers={checkAnswers}
                                showCorrectAns={showCorrectAns}
                                correctAnswer={correctAnswer}
                                finishedGame={finishedGame}
                                questionNumber={questionNumber}
                                setQuestionNumber={setQuestionNumber}
                                questionAnswered={questionAnswered}
                                setQuestionAnswered={setQuestionAnswered}
                                next={next}
                                userSelectedBoxes={userSelectedBoxes}
                                setUserSelectedBoxes={setUserSelectedBoxes}
                            />
                        </FormatContext.Provider>
                    ))}

                </>
            }
        </div>
    )
}
