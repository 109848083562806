import React, { useEffect, useState } from "react"
import { onMessage, isSupported } from "firebase/messaging"
import { token } from "./firebase"
import { FormatContext } from "./context/FormatContext.js"
import getWindowDimensions from "./components/util/getWindowDimensions"
import { useLocalStorage } from "./context/AuthContext"
import ReactNotification from "./components/Notifications/ReactNotification"
import AppRouter from "./AppRouter"
import { messaging } from "./firebase"

export default function App() {
  const [showNotification, setShowNotification] = useState(false)
  const [notification, setNotification] = useState(false)
  const [initialStyle, setInitialStyle] = useState(true)
  const { format, height } = getWindowDimensions()
  //eslint-disable-next-line no-unused-vars
  const [initialTheme, setinitialTheme] = useLocalStorage("theme", "dark")
  const [theme, setTheme] = useState(initialTheme)


  useEffect(() => {
    setInitialStyle(true)
    if (initialTheme === 'dark') {
      document.body.style.setProperty('--body-animation', 'none')
      document.body.style.setProperty('--body-filter', 'brightness(0.5)')
      document.body.style.setProperty('--root-background-color', 'rgb(35, 35, 39)')
      document.body.style.setProperty('--text-color', '#cacaca')
      document.body.style.setProperty('--accent-color', '#2f2f2f')
      document.body.style.setProperty('--dark-accent-color', '#000000')
      document.body.style.setProperty('--text-accent-color', '#cacaca')
      document.body.style.setProperty('--text-accent-color-arrow', '#cacaca')
      document.body.style.setProperty('--text-accent-color-arrow-box', '#2f2f2f')
      document.body.style.setProperty('--pulse-color', '#fafafa')
      document.body.style.setProperty('--pulse-accent-color', '#ffffff')
    }
    else {
      document.body.style.setProperty('--body-animation', 'none')
      document.body.style.setProperty('--body-filter', 'brightness(1)')
      document.body.style.setProperty('--root-background-color', 'rgb(254, 255, 248)')
      document.body.style.setProperty('--text-color', '#000000')
      document.body.style.setProperty('--accent-color', '#eaeaea')
      document.body.style.setProperty('--dark-accent-color', 'rgb(47, 47, 47)')
      document.body.style.setProperty('--text-accent-color', '#000000')
      document.body.style.setProperty('--text-accent-color-arrow', '#000000')
      document.body.style.setProperty('--text-accent-color-arrow-box', 'transparent')
      document.body.style.setProperty('--pulse-color', 'rgb(153, 153, 153)')
      document.body.style.setProperty('--pulse-accent-color', 'rgb(153, 153, 153)')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (theme === 'dark' && initialStyle === false) {
      document.body.style.setProperty('--body-animation', 'lightToDarkBody 0.2s ease-in-out forwards')
      document.body.style.setProperty('--body-filter', 'none')
      document.body.style.setProperty('--root-background-color', 'rgb(35, 35, 39)')
      document.body.style.setProperty('--text-color', '#cacaca')
      document.body.style.setProperty('--accent-color', '#2f2f2f')
      document.body.style.setProperty('--dark-accent-color', '#000000')
      document.body.style.setProperty('--text-accent-color', '#cacaca')
      document.body.style.setProperty('--text-accent-color-arrow', '#cacaca')
      document.body.style.setProperty('--text-accent-color-arrow-box', '#2f2f2f')
      document.body.style.setProperty('--pulse-color', '#fafafa')
      document.body.style.setProperty('--pulse-accent-color', '#ffffff')
      document.getElementById("header").style.animation = 'darkToLightRoot 0.2s ease-in-out forwards'
      document.querySelector(".start--main").style.animation = 'darkToLightRoot 0.2s ease-in-out forwards'
    }
    else if (theme === 'light' && initialStyle === false) {
      document.body.style.setProperty('--body-animation', 'darkToLightBody 0.2s ease-in-out forwards')
      document.body.style.setProperty('--body-filter', 'none')
      document.body.style.setProperty('--root-background-color', 'rgb(254, 255, 248)')
      document.body.style.setProperty('--text-color', '#000000')
      document.body.style.setProperty('--accent-color', '#cacaca')
      document.body.style.setProperty('--dark-accent-color', 'rgb(47, 47, 47)')
      document.body.style.setProperty('--text-accent-color', '#000000')
      document.body.style.setProperty('--text-accent-color-arrow', '#000000')
      document.body.style.setProperty('--text-accent-color-arrow-box', 'transparent')
      document.body.style.setProperty('--pulse-color', 'rgb(153, 153, 153)')
      document.body.style.setProperty('--pulse-accent-color', 'rgb(153, 153, 153)')
      document.getElementById("header").style.animation = 'lightToDarkRoot 0.2s ease-in-out forwards'
      document.querySelector(".start--main").style.animation = 'lightToDarkRoot 0.2s ease-in-out forwards'
    }
    setInitialStyle(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  messaging && isSupported() && onMessage(messaging, async (payload) => {
    setShowNotification(true)
    setNotification(payload)
  })

  messaging && isSupported() && token()

  useEffect(() => {
    document.body.style.setProperty('--body-height', `${height}px`)
  }, [height])


  return (
    <>
      <FormatContext.Provider value={{ format, theme, setTheme }}>
        {showNotification && (
          <ReactNotification
            title={notification.data.title}
            body={notification.data.body}
            icon={notification.data.icon}
            type={notification.data.errorType}
            animationLength={notification.data.animationLength}
            gameID={notification.data.gameID}
            setShowNotification={setShowNotification}
          />
        )}
        <AppRouter setShowNotification={setShowNotification} setNotification={setNotification} />
      </FormatContext.Provider>
    </>
  )
}
