import React, { useContext, useEffect } from 'react'
import { ReactComponent as Logo } from '../img/asterisk-solid.svg'
import { FormatContext } from '../context/FormatContext'

export default function Features() {
  const { theme, format } = useContext(FormatContext)
  const items = ['.one', '.three', '.four', '.five', '.nine', '.six', '.two', '.seven', '.eight']

  useEffect(() => {
    const unsubscribe = () => {
      clearInterval(pulse)
    }

    const pulse = setInterval(() => {
      for (let i = 0; i < items.length; i++) {
        const element = document.querySelector(items[i])
        setTimeout(() => {
          element.style.marginLeft = "0"
          element.style.animation = 'showLine 0.15s ease-in-out forwards'
          setTimeout(() => {
            element.style.marginLeft = "auto"
            element.style.animation = 'hideLine 0.15s ease-in-out forwards'
          }, 150)
        }, i * 50)
      }
    }, 5000)

    return unsubscribe
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [format])

  function showLine(modal, id) {
    document.getElementById(id).style.animation = 'turnAsteriskClockwise 0.3s ease-in-out forwards'
    const modalQuery = document.querySelectorAll(modal)
    modalQuery.forEach(modal => {
      modal.style.marginLeft = "0"
      modal.style.animation = 'showLine 0.3s ease-in-out forwards'
    })
  }

  function hideLine(modal, id) {
    document.getElementById(id).style.animation = 'turnAsteriskCounterClockwise 0.3s ease-in-out forwards'
    const modalQuery = document.querySelectorAll(modal)
    modalQuery.forEach(modal => {
      modal.style.marginLeft = "auto"
      modal.style.animation = 'hideLine 0.3s ease-in-out forwards'
    })
  }

  return (
    <div className='features--container'>
      <div className='features--text'>
        <h1>The features of .quiz</h1>
      </div>
      <div className='features--list'>
        <div className='features--item' onMouseEnter={() => showLine(".one", "one")} onMouseLeave={() => hideLine(".one", "one")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='one' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3><span style={{ fontWeight: "900", fontSize: "100%", color: "#62dafb" }}>React</span>-based application</h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line one'></div>

        <div className='features--item' onMouseEnter={() => showLine(".three", "three")} onMouseLeave={() => hideLine(".three", "three")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='three' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Authentication through <span style={{ fontWeight: "900", fontSize: "100%", color: "rgb(193, 127, 1)" }}>Firebase</span></h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line three'></div>

        <div className='features--item' onMouseEnter={() => showLine(".four", "four")} onMouseLeave={() => hideLine(".four", "four")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='four' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Online, realtime gameplay through <span style={{ fontWeight: "900", fontSize: "100%", color: "rgb(139, 27, 170)" }}>Firestore</span></h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line four'></div>

        <div className='features--item' onMouseEnter={() => showLine(".five", "five")} onMouseLeave={() => hideLine(".five", "five")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='five' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Notification capabilities through <span style={{ fontWeight: "900", fontSize: "100%", color: "rgb(3, 61, 234)" }}>Firebase Cloud Messaging</span></h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line five'></div>

        <div className='features--item' onMouseEnter={() => showLine(".nine", "nine")} onMouseLeave={() => hideLine(".nine", "nine")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='nine' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Backend serverless functions through <span style={{ fontWeight: "900", fontSize: "100%", color: "rgb(89, 200, 79)" }}>Firebase Cloud Functions</span></h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line nine'></div>

        <div className='features--item' onMouseEnter={() => showLine(".six", "six")} onMouseLeave={() => hideLine(".six", "six")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='six' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Custom <span style={{ fontWeight: "900", fontSize: "100%", color: "rgb(193, 127, 1)" }}>Firebase</span> security logic</h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line six'></div>

        <div className='features--item' onMouseEnter={() => showLine(".two", "two")} onMouseLeave={() => hideLine(".two", "two")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='two' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Entirely designed, styled and developed by me</h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line two'></div>

        <div className='features--item' onMouseEnter={() => showLine(".seven", "seven")} onMouseLeave={() => hideLine(".seven", "seven")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='seven' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Dark mode</h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line seven'></div>

        <div className='features--item' onMouseEnter={() => showLine(".eight", "eight")} onMouseLeave={() => hideLine(".eight", "eight")}>
          <div className='features--item--inner'>
            <Logo className='asterisk' id='eight' fill={theme === "dark" ? "#cacaca" : "#000000"} />
            <h3>Responsive design</h3>
          </div>
          <div className='bottom--line--static'></div>
        </div>
        <div className='bottom--line eight'></div>

      </div>
    </div>
  )
}
