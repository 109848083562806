import React, { useRef, useState, useContext } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { FormatContext } from '../../context/FormatContext'


export default function Login({ setNotification, setShowNotification }) {

    const emailRef = useRef(null)
    const passwordRef = useRef(null)
    const { login } = useAuth()
    const { theme, format } = useContext(FormatContext)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [emailSelected, setEmailSelected] = useState(false)
    const [passwordSelected, setPasswordSelected] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        const email = emailRef.current.value
        const password = passwordRef.current.value

        try {
            setLoading(true)
            await login(email, password)
            navigate('/dashboard')
        } catch (error) {
            if (error.message === "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).") {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Invalid email or password`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
            else {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Failed to log in`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
        }
        setLoading(false)
    }

    const emailStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: emailSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const passwordStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: passwordSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const emailBorderStyle = {
        animation: emailSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const passwordBorderStyle = {
        animation: passwordSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const darkMode = {
        background: theme === "dark" ? "#2f2f2f" : "#FFFFFF",
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
        caretColor: theme === "dark" ? "#cacaca" : "#2f2f2f",
        animation: (format === 1 || format === 2) ? "authFadeIn 0.3s ease-in-out forwards" : "none",
    }

    return (
        <>
            <div className="signup--card" style={darkMode} onClick={(e) => e.stopPropagation()}>
                <div className="signup--card--header" style={darkMode}>
                    <h1 className="signup--card--header--title">Log in</h1>
                </div>
                <div className="signup--card-body" style={darkMode}>
                    <form onSubmit={handleSubmit}>
                        <div id="email">
                            <label style={emailStyle}>Enter email</label>
                            <input type="email" ref={emailRef} style={emailBorderStyle} onFocus={() => setEmailSelected(true)} onBlur={(e) => { if (e.target.value === '') { setEmailSelected(false) } }} className="input--field mb-1" required />
                        </div>
                        <div id="password">
                            <label style={passwordStyle}>Enter password</label>
                            <input type="password" className="input--field mb-1" style={passwordBorderStyle} onFocus={() => setPasswordSelected(true)} onBlur={(e) => { if (e.target.value === '') { setPasswordSelected(false) } }} ref={passwordRef} required />
                        </div>
                        <button type="submit" disabled={loading} className="w-100 mt-2 auth--button">Log in</button>
                        <button type="button" disabled={loading} className="w-100 mt-2 auth--button" style={{ background: "#4f4f4f", color: "#f1f1f1" }} onClick={() => navigate('/auth/signup/guest')}>Log in as guest</button>
                    </form>
                </div>
                <div className="signup--card--footer">
                    Don't have an account? <Link to={'/auth/signup'}>Sign up</Link>
                </div>
            </div>
        </>
    )
}
