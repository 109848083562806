import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { nanoid } from 'nanoid'
import Question from '../Question.js'
import { FormatContext } from '../../context/FormatContext.js'
import { useNavigate } from 'react-router-dom'
import { database, functions } from '../../firebase'
import { useAuth, useLocalStorage } from '../../context/AuthContext'
import { CategoryContext } from '../../context/CategoryContext.js'
import { httpsCallable } from 'firebase/functions'
import { useLocation } from 'react-router-dom'
import GameHeader from '../GameHeader.js'

export default function GameOnline() {

    const [quiz, setQuiz] = useState()
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [finishedGame, setFinishedGame] = useState(false)
    const [showCorrectAns, setShowCorrectAns] = useState(false)
    const [questionNumber, setQuestionNumber] = useState(0)
    const [questionAnswered, setQuestionAnswered] = useState(false)
    const [userSelectedBoxes, setUserSelectedBoxes] = useState([
        'box--default',
        'box--default',
        'box--default',
        'box--default',
        'box--default'
    ])
    const { format, theme } = useContext(FormatContext)
    const { currentUser, userParams } = useAuth()
    const { category, setCategory } = useContext(CategoryContext)
    const location = useLocation()
    const id = location?.state?.id
    let opponent = location?.state?.opponent
    const requestID = location?.state?.requestID
    const pathname = location?.state.pathname
    const allCategories = [2, 3, 13, 14, 25, 26]
    const navigate = useNavigate()
    const [storedGameID, setStoredGameID] = useLocalStorage('gameID', null)
    const [gameID, setGameID] = useState(id)
    const [gameIDAlreadyFetched, setGameIDAlreadyFetched] = useState(false)
    const [catTitle, setCatTitle] = useState('')
    const [currentGame, setCurrentGame] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('.start--main').setAttribute('style', 'padding: 85px 0 0 0 !important');
        const unsubscribe = () => {
            document.querySelector('.start--main').setAttribute('style', 'padding: 85px 0 100px 0 !important');
        }
        return unsubscribe
    }, [])

    useEffect(() => {
        if (id) {
            setStoredGameID(id)
        }
        else if (storedGameID) {
            setGameID(storedGameID)
        }
        if ((gameID || requestID)) {
            if (gameIDAlreadyFetched) {
                return
            }
            fetchQuestions()
        }
        else {
            return navigate('/dashboard')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameID])



    const fetchQuestions = async () => {

        setQuiz(undefined)

        createGameInDatabase()
        setCorrectAnswer(0)
        setFinishedGame(false)
        setShowCorrectAns(false)
        setUserSelectedBoxes([
            'box--default',
            'box--default',
            'box--default',
            'box--default',
            'box--default'
        ])

    }

    async function sendNotification(content, id) {
        // get user token from firestore database

        const data = {
            ...content,
            userId: id
        }

        const notification = httpsCallable(functions, "sendNotification")
        notification(data).then((res) => {
            // console.log(res)
        })
    }

    const createGameInDatabase = async () => {
        const request =
            await database.requests.doc(requestID)
                .get()
                .then(doc => {
                    return doc.data()
                }
                )

        database.requests.doc(requestID).delete()
        await database.games.doc(gameID)
            .get()
            .then(doc => {
                return doc.data()
            })
            .then(doc => {

                let round
                if (doc !== undefined) {
                    doc.player1Id === currentUser.uid ? round = doc.player1Round - 1 : round = doc.player2Round - 1
                    if (doc.rounds[round] !== undefined) {

                        setCategory(doc.rounds[round].category)
                        setCatTitle(categoryTitle(doc.rounds[round].category))
                        let setRound = 0
                        let boxes = []
                        const roundQuestions = doc.rounds[round].questions
                        if ((roundQuestions.every(question => question.player1Answer === null) && doc.player1Id === currentUser.uid) || (roundQuestions.every(question => question.player2Answer === null) && doc.player2Id === currentUser.uid)) {
                            return next(false, false)
                        }

                        roundQuestions.forEach((question, index) => {
                            if ((doc.player1Id === currentUser.uid && question.player1Answer !== "") || (doc.player2Id === currentUser.uid && question.player2Answer !== "")) {
                                setRound = setRound + 1
                                if (doc.player1Id === currentUser.uid && question.player1Correct === true)
                                    boxes[index] = "box--correct"
                                else if (doc.player2Id === currentUser.uid && question.player2Correct === true)
                                    boxes[index] = "box--correct"
                                else
                                    boxes[index] = "box--incorrect"
                            }
                            else {
                                boxes[index] = "box--default"
                            }
                            setUserSelectedBoxes(boxes)
                        })
                        if ((setRound === 5) && ((roundQuestions.every(question => question.player1Answer !== "") && doc.player1Id === currentUser.uid) || (roundQuestions.every(question => question.player2Answer !== "") && doc.player2Id === currentUser.uid))) {
                            return next(false, true)
                        }
                        else {
                            setCurrentGame(doc)
                            setQuestionNumber(setRound)
                        }
                    }
                }

                if (doc === undefined) {

                    let url
                    if (category?.url !== undefined) {
                        url = `https://opentdb.com/api.php?amount=5${category.url}`
                    }
                    else {
                        setCategory({
                            title: "All Categories",
                            url: undefined,
                            id: "1",
                            short: "suprise_me"
                        })
                        url = 'https://opentdb.com/api.php?amount=5'
                    }
                    setCatTitle(categoryTitle())
                    axios.request({
                        method: "GET",
                        url: url,
                        responseEncoding: 'binary'
                    }).then(res => generateQuestions(res)).then(generatedQuestions => {

                        const generatedCategory = category ?
                            {
                                title: category?.title,
                                short: category?.short,
                            }
                            :
                            {
                                title: "All Categories",
                                short: "suprise_me",
                            }


                        const gameStructure = {
                            player1Id: currentUser.uid,
                            player2Id: request.requester_id,
                            player1Name: userParams.displayName,
                            player2Name: request.requester_name,
                            player1Score: 0,
                            player2Score: 0,
                            player1Round: 1,
                            player2Round: 1,
                            player1Won: false,
                            player2Won: false,
                            playerTurn: currentUser.uid,
                            rounds:
                                [
                                    {
                                        category: generatedCategory,
                                        questions:
                                            [
                                                {
                                                    question: generatedQuestions[0],
                                                    player1Answer: '',
                                                    player2Answer: '',
                                                    player1Correct: false,
                                                    player2Correct: false,
                                                    player1StartTime: null,
                                                    player2StartTime: null,
                                                },
                                                {
                                                    question: generatedQuestions[1],
                                                    player1Answer: '',
                                                    player2Answer: '',
                                                    player1Correct: false,
                                                    player2Correct: false,
                                                    player1StartTime: null,
                                                    player2StartTime: null,
                                                },
                                                {
                                                    question: generatedQuestions[2],
                                                    player1Answer: '',
                                                    player2Answer: '',
                                                    player1Correct: false,
                                                    player2Correct: false,
                                                    player1StartTime: null,
                                                    player2StartTime: null,
                                                },
                                                {
                                                    question: generatedQuestions[3],
                                                    player1Answer: '',
                                                    player2Answer: '',
                                                    player1Correct: false,
                                                    player2Correct: false,
                                                    player1StartTime: null,
                                                    player2StartTime: null,
                                                },
                                                {
                                                    question: generatedQuestions[4],
                                                    player1Answer: '',
                                                    player2Answer: '',
                                                    player1Correct: false,
                                                    player2Correct: false,
                                                    player1StartTime: null,
                                                    player2StartTime: null,
                                                },
                                            ]
                                    },
                                ]
                        };

                        database.games.add(gameStructure)
                            .then(doc => {
                                setGameIDAlreadyFetched(true);
                                setGameID(doc.id);
                                setStoredGameID(doc.id);
                                setCurrentGame(gameStructure);
                            })
                            .then(setQuiz(generatedQuestions))
                    }).catch(err => {
                        // console.log(err)
                    }
                    )
                    return
                }
                else if (doc) {
                    if (doc.gameStarted === true && questionNumber !== 5 && ((doc.player1Id === currentUser.uid && doc.player1Round <= doc.rounds.length) || (doc.player2Id === currentUser.uid && doc.player2Round <= doc.rounds.length))) {
                        const prevQuiz = doc.rounds[doc.player1Id === currentUser.uid ? doc.player1Round - 1 : doc.player2Round - 1]
                        const prevQuizQuestions = prevQuiz.questions
                        setCategory(doc.rounds[doc.player1Id === currentUser.uid ? doc.player1Round - 1 : doc.player2Round - 1].category)
                        let newQuiz = []
                        newQuiz.push(prevQuizQuestions[0].question, prevQuizQuestions[1].question, prevQuizQuestions[2].question, prevQuizQuestions[3].question, prevQuizQuestions[4].question)
                        setQuiz(newQuiz)
                        setCatTitle(categoryTitle(doc.rounds[doc.player1Id === currentUser.uid ? doc.player1Round - 1 : doc.player2Round - 1].category))
                        return
                    }
                    else {
                        if (pathname !== "/choose-category" && doc.gameStarted === true) return navigate('/choose-category', { state: { id, opponent } })
                        let url
                        if (category?.url !== undefined) {
                            url = `https://opentdb.com/api.php?amount=5${category.url}`
                        }
                        else {
                            setCategory({
                                title: "All Categories",
                                short: "suprise_me"
                            })
                            url = 'https://opentdb.com/api.php?amount=5'
                        }
                        setCatTitle(categoryTitle())
                        axios.request({
                            method: "GET",
                            url: url,
                            responseEncoding: 'binary'
                        }).then(res => generateQuestions(res)).then(generatedQuestions => {
                            const altCategory = {
                                title: category?.title,
                                short: category?.short,
                            }
                            const generatedCategory = category ? altCategory
                                :
                                {
                                    title: "All Categories",
                                    short: "suprise_me",
                                }
                            setQuiz(generatedQuestions)

                            database.games.doc(gameID).update({
                                gameStarted: true,
                                rounds:
                                    [
                                        ...doc.rounds,
                                        {
                                            category: generatedCategory,
                                            questions:
                                                [
                                                    {
                                                        question: generatedQuestions[0],
                                                        player1Answer: '',
                                                        player2Answer: '',
                                                        player1Correct: false,
                                                        player2Correct: false,
                                                        player1StartTime: null,
                                                        player2StartTime: null,
                                                    },
                                                    {
                                                        question: generatedQuestions[1],
                                                        player1Answer: '',
                                                        player2Answer: '',
                                                        player1Correct: false,
                                                        player2Correct: false,
                                                        player1StartTime: null,
                                                        player2StartTime: null,
                                                    },
                                                    {
                                                        question: generatedQuestions[2],
                                                        player1Answer: '',
                                                        player2Answer: '',
                                                        player1Correct: false,
                                                        player2Correct: false,
                                                        player1StartTime: null,
                                                        player2StartTime: null,
                                                    },
                                                    {
                                                        question: generatedQuestions[3],
                                                        player1Answer: '',
                                                        player2Answer: '',
                                                        player1Correct: false,
                                                        player2Correct: false,
                                                        player1StartTime: null,
                                                        player2StartTime: null,
                                                    },
                                                    {
                                                        question: generatedQuestions[4],
                                                        player1Answer: '',
                                                        player2Answer: '',
                                                        player1Correct: false,
                                                        player2Correct: false,
                                                        player1StartTime: null,
                                                        player2StartTime: null,
                                                    }
                                                ]
                                        }
                                    ]
                            }).then(database.games.doc(gameID).get().then(doc => { setCurrentGame(doc.data()) }))
                            // database.requests.doc(request.id).delete()
                        }).catch(err => {
                            // console.log(err)
                        }
                        )
                    }
                }
                opponent = request?.requester_name
                return doc
            })








    }

    function goToMain() {
        if (questionNumber >= 5) {
            navigate('/')
        }
        else {
            next(true, false)
        }
    }

    function next(exited, naughty) {
        if (exited && naughty === false) {
            checkAnswers(quiz, null, quiz[questionNumber].id, questionNumber)
            navigate('/')
        }
        if (!exited && naughty === false) {
            setQuestionNumber(prev => prev + 1)
            setQuestionAnswered(false)
        }
        if (finishedGame === true && questionNumber < 5 && naughty === false) {
            setCorrectAnswer(0)
            setQuestionNumber(0)
        }
        else if (questionNumber >= 4 || naughty) {
            const data = async () => {
                await database.games.doc(gameID).get().then(doc => {
                    const data = doc.data()
                    const player1 = data.player1Id
                    const player2 = data.player2Id
                    let player1Round = data.player1Round
                    let player2Round = data.player2Round
                    const playerTurn = data.playerTurn
                    const playing = player1 === currentUser.uid ? player1Round : player2Round
                    const opponent = player1 === currentUser.uid ? player2Round : player1Round
                    if (player1Round === 6 && player2Round === 7) {
                        console.log("%cGame finished", "color: red")
                        if (doc.data().player1Score === data.player2Score) {
                            database.games.doc(gameID).update({
                                player1Won: true,
                                player2Won: true,
                            })
                            navigate('/inspectgame', { state: { id: gameID } })
                            return
                        }
                        else if (doc.data().player1Score > data.player2Score) {
                            database.games.doc(gameID).update({
                                player1Won: true,
                            })
                            navigate('/inspectgame', { state: { id: gameID } })
                            return
                        }

                        else {
                            database.games.doc(gameID).update({
                                player2Won: true,
                            })
                            navigate('/inspectgame', { state: { id: gameID } })
                            return
                        }
                    }
                    else if ((currentUser.uid === playerTurn && playing === opponent) || (currentUser.uid === playerTurn && playing === 1 && opponent === 1)) {
                        console.log("%cChanged player turn", "color: red")
                        database.games.doc(gameID).update({
                            playerTurn: player1 === currentUser.uid ? player2 : player1,
                            player1Round: player1 === currentUser.uid ? player1Round + 1 : player1Round,
                            player2Round: player1 === currentUser.uid ? player2Round : player2Round + 1,
                        })
                        const message = {
                            title: `Your turn against ${player1 === currentUser.uid ? data.player1Name : data.player2Name}`,
                            body: `${player1 === currentUser.uid ? data.player1Name : data.player2Name} has played round ${player1 === currentUser.uid ? data.player2Round : data.player1Round}!`,
                            onClick: `https://thelin.app/inspectgame/${gameID}`,
                            gameID: gameID,
                            errorType: "success",
                            animationLength: "5s",
                        }
                        player1Round = player1 === currentUser.uid ? player1Round + 1 : player1Round
                        player2Round = player1 === currentUser.uid ? player2Round : player2Round + 1
                        sendNotification(message, player1 === currentUser.uid ? data.player2Id : data.player1Id)
                        setCategory(null)
                        navigate('/inspectgame', { state: { id: gameID, opponent: player1 === currentUser.uid ? data.player2Name : data.player1Name } })
                    }
                    else if (currentUser.uid === playerTurn && playing < opponent) {
                        console.log("%cDid not change turns yet", "color: red")
                        database.games.doc(gameID).update({
                            player1Round: player1 === currentUser.uid ? player1Round + 1 : player1Round,
                            player2Round: player1 === currentUser.uid ? player2Round : player2Round + 1,
                        })
                        player1Round = player1 === currentUser.uid ? player1Round + 1 : player1Round
                        player2Round = player1 === currentUser.uid ? player2Round : player2Round + 1
                        setCategory(null)
                        navigate('/choose-category', { state: { id: gameID, opponent: player1 === currentUser.uid ? data.player2Name : data.player1Name } })
                    }

                    setFinishedGame(true)
                })
            }
            data()

        }
        if (exited && questionNumber === 4) {
            navigate('/')
        }
    }

    function handleSelection(ansId, queId, i) {
        let content
        const ans = quiz.map(prev => {
            let ans = prev.answers.map(p => {
                if (ansId === p.id && p.selected === false && queId === prev.id) {
                    return {
                        ...p,
                        selected: true,
                        correct: false,
                        incorrect: false
                    }
                }
                else if (ansId !== p.id) {
                    return {
                        ...p,
                        selected: false,
                        correct: false,
                        incorrect: false
                    }
                }
                return p
            }
            )
            return { ...prev, answers: ans }
        })
        content = ans
        setQuiz(content)
        setTimeout(() => checkAnswers(content, ansId, queId, i), 1000)
    }

    async function checkAnswers(content, ansId, queId, i) {

        setQuestionAnswered(true)
        await database.games.doc(gameID).get().then(doc => {
            return doc.data()
        }).then(doc => {


            const roundNr = doc.rounds.length - 1
            const modifiedQuiz = content.map(prevQuiz => {

                let noAnswer = false
                if (prevQuiz.id === queId && prevQuiz.answers.every(p => p.selected === false)) {

                    noAnswer = true
                }
                let ans = prevQuiz.answers.map(p => {
                    if (prevQuiz.id === queId) {
                        let player1Answer = doc.rounds[roundNr].questions[i].player1Answer
                        let player2Answer = doc.rounds[roundNr].questions[i].player2Answer
                        let player1Correct = doc.rounds[roundNr].questions[i].player1Correct
                        let player2Correct = doc.rounds[roundNr].questions[i].player2Correct
                        let player1Score = doc.player1Score
                        let player2Score = doc.player2Score
                        let roundsArray = doc.rounds
                        let rounds = doc.rounds[roundNr]
                        let questionArray = rounds.questions
                        let question = questionArray[i]
                        if (noAnswer === false) {
                            if (p.answer === htmlDecode(prevQuiz.correctAns) && p.selected === true) {
                                doc.player1Id === currentUser.uid ? player1Answer = p.answer : player2Answer = p.answer
                                doc.player1Id === currentUser.uid ? player1Correct = true : player2Correct = true
                                doc.player1Id === currentUser.uid ? player1Score = player1Score + 1 : player2Score = player2Score + 1
                                userSelectedBoxes[i] = 'box--correct'
                                setCorrectAnswer(prev => prev + 1)
                            }
                            else if (p.answer !== htmlDecode(prevQuiz.correctAns) && p.selected === true) {
                                doc.player1Id === currentUser.uid ? player1Answer = p.answer : player2Answer = p.answer
                                doc.player1Id === currentUser.uid ? player1Correct = false : player2Correct = false
                                userSelectedBoxes[i] = 'box--incorrect'
                            }
                        }
                        else {
                            doc.player1Id === currentUser.uid ? player1Answer = "NOANSWER-NULL" : player2Answer = "NOANSWER-NULL"
                            doc.player1Id === currentUser.uid ? player1Correct = false : player2Correct = false
                            userSelectedBoxes[i] = 'box--incorrect'
                        }

                        question = {
                            ...question,
                            player1Answer: player1Answer,
                            player2Answer: player2Answer,
                            player1Correct: player1Correct,
                            player2Correct: player2Correct,
                        }

                        questionArray[i] = question
                        rounds.questions = questionArray
                        roundsArray[roundNr] = rounds



                        doc = {
                            ...doc,
                            rounds: roundsArray,
                            player1Score: player1Score,
                            player2Score: player2Score,
                        }

                        database.games.doc(gameID).update(doc)
                        setCurrentGame(doc)


                        if (ansId === null) {
                            if (p.answer === htmlDecode(prevQuiz.correctAns)) {
                                return { ...p, correct: true, incorrect: false }
                            }
                            else if (p.answer !== htmlDecode(prevQuiz.correctAns)) {
                                doc.player1Id === currentUser.uid ? player1Correct = false : player2Correct = false
                                return { ...p, incorrect: true, correct: false, noAnswer: true }
                            }
                        }
                        else {
                            if (p.answer === htmlDecode(prevQuiz.correctAns)) {
                                return { ...p, correct: true, incorrect: false }
                            }
                            else if (p.answer !== htmlDecode(prevQuiz.correctAns)) {
                                return { ...p, incorrect: true, correct: false }
                            }
                        }

                        return p
                    }
                    return p
                })

                return ans
            })

            setQuiz(prev => {
                const obj = prev.map((p, index) => ({ ...p, answers: modifiedQuiz[index] }))
                return obj
            })
        })
    }

    const htmlDecode = (input) => {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    function generateQuestions(data) {
        if (allCategories.includes(category?.id)) {
            let ques
            let populatedArray = {}
            let subCategories = []
            switch (category.id) {
                case 2:
                case 3:
                    subCategories = [20, 21, 22, 23, 24, 25, 26, 27, 28]
                    break;
                case 13:
                case 14:
                    subCategories = [10, 11, 12, 13, 14, 15, 16, 29, 31, 32]
                    break;
                case 25:
                case 26:
                    subCategories = [17, 18, 19, 30]
                    break;
                default:
                    break;
            }
            setQuiz(undefined)
            const getFive = async () => {
                let resultsArray = []
                let dataObj = {}
                for (let i = 0; i < 5; i++) {
                    const randomIndex = Math.floor(Math.random() * subCategories.length)
                    const catUrl = subCategories[randomIndex]
                    const response = await axios.request({
                        method: "GET",
                        url: `https://opentdb.com/api.php?amount=1&category=${catUrl}`,
                        responseEncoding: 'binary'
                    })
                    dataObj = response.data
                    resultsArray.push(response.data.results[0])
                    populatedArray = { ...response }
                }
                dataObj = { ...dataObj, results: resultsArray }
                populatedArray = { ...populatedArray, data: dataObj }
                ques = await generate(populatedArray)
                setCorrectAnswer(0)
                setFinishedGame(false)
                setShowCorrectAns(false)
                // console.log(ques)
                return ques
            }
            const data = getFive().then(res => {
                return res
            })
            return data
        }
        else {
            return generate(data)
        }
    }

    function generate(data) {
        let content = data.data.results.map(result => {
            let answers = []

            const length = result.incorrect_answers.length

            if (length > 1) {
                result.incorrect_answers.map(ans => {
                    answers.push({
                        id: nanoid(),
                        answer: htmlDecode(ans),
                        selected: false,
                        correct: false,
                        incorrect: false
                    })
                    return ans
                })
                answers.splice(
                    Math.ceil(
                        Math.random() * (4) - 1), 0, {
                    id: nanoid(),
                    answer: htmlDecode(result.correct_answer),
                    selected: false,
                    correct: false,
                    incorrect: false
                })
            }
            else {

                answers.push({
                    id: nanoid(),
                    answer: htmlDecode(result.incorrect_answers[0]),
                    selected: false,
                    correct: false,
                    incorrect: false
                })

                answers.splice(
                    result.correct_answer === "True" ? 0 : 1, 0, {
                    id: nanoid(),
                    answer: htmlDecode(result.correct_answer),
                    selected: false,
                    correct: false,
                    incorrect: false
                })
            }
            return ({
                id: nanoid(),
                question: htmlDecode(result.question),
                answers: answers,
                correctAns: htmlDecode(result.correct_answer)
            })
        })
        return content
    }

    function categoryTitle(cat) {
        if (cat) {
            if (format && format === 0) {
                if (cat.title === 'All categories') {
                    return `Playing all categories against ${opponent}`
                }
                else {
                    const title = cat.title.charAt(0).toLowerCase() + cat.title.slice(1)
                    return `Playing the ${title} category against ${opponent}`
                }
            }
            else {
                if (cat.title === 'All categories') {
                    return `All categories vs ${opponent}`
                }
                else {
                    return `${cat.title} vs ${opponent}`
                }
            }
        }
        else if (category) {
            if (format && format === 0) {
                if (category.title === 'All categories') {
                    return `Playing all categories against ${opponent}`
                }
                else {
                    const title = category.title.charAt(0).toLowerCase() + category.title.slice(1)
                    return `Playing the ${title} category against ${opponent}`
                }
            }
            else {
                if (category.title === 'All categories') {
                    return `All categories vs ${opponent}`
                }
                else {
                    return `${category.title} vs ${opponent}`
                }
            }
        }

        else {
            if (format && format === 0) {
                return `Playing all categories against ${opponent}`
            }
            else {
                return `All categories vs ${opponent}`
            }
        }
    }

    // console.log(currentGame)

    return (
        <div className='main--container'>
            {quiz === undefined ?
                <div>
                    <h1 className={theme === 'dark' ? 'loading--title light--text' : 'loading--title'}>Loading questions...</h1>
                </div>
                :
                <>
                    <GameHeader goToMain={goToMain} catTitle={catTitle} />
                    {quiz !== undefined && currentGame && quiz.map((q, index) => (
                        <FormatContext.Provider value={{ format, theme }} key={`provider ${index}`}>
                            <Question
                                key={`Question ${index}`}
                                quiz={quiz}
                                questionIndex={index}
                                question={q.question}
                                answers={q.answers}
                                questionId={q.id}
                                selection={handleSelection}
                                checkAnswers={checkAnswers}
                                showCorrectAns={showCorrectAns}
                                correctAnswer={correctAnswer}
                                finishedGame={finishedGame}
                                questionNumber={questionNumber}
                                setQuestionNumber={setQuestionNumber}
                                questionAnswered={questionAnswered}
                                setQuestionAnswered={setQuestionAnswered}
                                next={next}
                                userSelectedBoxes={userSelectedBoxes}
                                setUserSelectedBoxes={setUserSelectedBoxes}
                                currentGame={currentGame}
                                gameID={gameID}
                            />
                        </FormatContext.Provider>
                    ))}

                    {/* {(questionAnswered || finishedGame) &&
                        <div style={{ overflow: 'hidden', height: "75px", width: "100%", position: "fixed", bottom: "0", left: "0" }}>
                            <div
                                id={theme === 'dark' ? "resetButtonDark" : "resetButton"}
                                className={format === 0 ? 'main--button reset--button noSelect' : 'start--button noSelect'}
                                onClick={() => next(false)}>{finishedGame ? "Reset game" : "Next question"}
                            </div>
                        </div>
                    } */}

                </>
            }
        </div>
    )
}
