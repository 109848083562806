import React, { useState } from "react"
import StartScreen from "./components/StartScreen"
import Game from "./components/Game"
import { CategoryContext } from "./context/CategoryContext.js"
import Signup from "./components/auth-components/Signup"
import Login from "./components/auth-components/Login"
import ChooseCategory from "./components/ChooseCategory"
import GameOnline from "./components/online-specific/GameOnline"
import categoriesArray from "./categories.json"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PrivateRoute from "./components/auth-components/PrivateRoute"
import Dashboard from "./components/online-specific/Dashboard"
import InspectGame from "./components/online-specific/InspectGame"
import BottomNav from "./components/BottomNav"
import NewGame from "./components/online-specific/NewGame"
import SignupGuest from "./components/auth-components/SignupGuest"
import GameRoute from "./components/auth-components/GameRoute"
import ChooseCategoryRoute from "./components/auth-components/ChooseCategoryRoute"
import Features from "./components/Features"
import AboutMe from "./components/AboutMe"
import Layout from "./components/util/Layout"

export default function AppRouter({ setNotification, setShowNotification }) {

  const [categories] = useState(categoriesArray)
  const [category, setCategory] = useState({
    title: "All categories",
    url: undefined,
  })

  return (
    <Router>
      <CategoryContext.Provider value={{ category, setCategory }}>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={
            <Layout />
          } >
            <Route index element={
              <StartScreen category={category} categories={categories} setNotification={setNotification} setShowNotification={setShowNotification} />
            } >


            </Route>

            <Route path="features" element={
              <>
                <Features />
                <BottomNav back={true} />
              </>
            } />

            <Route path="aboutme" element={
              <>
                <AboutMe />
                <BottomNav back={true} />
              </>
            } />

            <Route path="game" element={
              <Game />
            } >
            </Route>

            <Route path="auth" element={
              <StartScreen category={category} categories={categories} setNotification={setNotification} setShowNotification={setShowNotification} />
            } >
              <Route path="login" element={
                <Login setNotification={setNotification} setShowNotification={setShowNotification} />
              } />

              <Route path="signup" element={
                <Signup setNotification={setNotification} setShowNotification={setShowNotification} />
              } />
              <Route path="signup/guest" element={
                <SignupGuest setNotification={setNotification} setShowNotification={setShowNotification} />
              } />
            </Route>





            <Route path="/game/online" element={
              <PrivateRoute>
                <GameRoute>
                  <GameOnline startTimer setStartTimer/>
                </GameRoute>
              </PrivateRoute>

            }
            />

            <Route path="dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />

            <Route path="dashboard/new-game" element={
              <PrivateRoute>
                <NewGame setNotification={setNotification} setShowNotification={setShowNotification} />
                <BottomNav back={true} local={true} />
              </PrivateRoute>
            } />

            <Route path="inspectgame" element={
              <PrivateRoute>
                <InspectGame setNotification={setNotification} setShowNotification={setShowNotification}/>
              </PrivateRoute>
            } />

            <Route path="choose-category" element={
              <ChooseCategoryRoute>
                <ChooseCategory />
              </ChooseCategoryRoute>
            } />
          </Route>
        </Routes>
      </CategoryContext.Provider>
    </Router>
  )
}