import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import "./style/index.css"
import "./style/mobile.css"
import "./style/dark.css"
import "./style/animations.css"
import "./style/online.css"
import "./style/desktop.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { AuthProvider } from "./context/AuthContext"



const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <AuthProvider>
      <App tab="home" />
  </AuthProvider>
)
