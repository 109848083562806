import React, { useState, useContext, useEffect } from "react"
import { database } from "../../firebase"
import { useAuth } from "../../context/AuthContext"
import { FormatContext } from "../../context/FormatContext"
import { ReactComponent as InviteLogo } from "../../img/paper-plane-solid.svg"
import { ReactComponent as RandomLogo } from "../../img/dice-solid.svg"
import { useNavigate } from "react-router-dom"
import { sendNotification } from "../util/sendNotification"

export default function NewGame({ setNotification, setShowNotification }) {
  const { currentUser, userParams } = useAuth()
  const { theme } = useContext(FormatContext)
  const [displayName, setDisplayName] = useState("")
  const [displayNameSelected, setDisplayNameSelected] = useState(false)
  const [currentUsers, setCurrentUsers] = useState(null)
  const containerStyle = {
    color: theme === "dark" ? "#cecece" : "#000",
  }
  const displayNameStyle = {
    transform: "translateY(25px) translatex(2px)",
    animation: displayNameSelected
      ? "labelFadeOutOfInput 0.1s ease-in-out forwards"
      : "labelFadeIntoInput 0.1s ease-in-out forwards",
    color: theme === "dark" ? "#cecece" : "#000",
  }
  const displayNameInputStyle = {
    animation: displayNameSelected
      ? "enlargeBorder 0.15s ease-in-out forwards"
      : "smollerBorder 0.15s ease-in-out forwards",
    color: theme === "dark" ? "#cecece" : "#000",
  }
  const navigate = useNavigate()

  useEffect(() => {
    function unsubscribe() {
      database.users.onSnapshot((snapshot) => {
        const users = []
        snapshot.forEach((doc) => {
          if (doc.id === currentUser.uid) {
            users.push({
              id: doc.id,
              data: doc.data(),
            })
          }
        })
        setCurrentUsers(users)
      })
    }
    return unsubscribe()
  }, [currentUser.uid])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleChange(e) {
    setDisplayName(e.target.value)
  }

  async function addPrevRequest(id, displayNameOr, idRequest, nameRequest) {
    const prevUserData = currentUsers[0].data
    const prevRequests = prevUserData.prevRequests
    if (prevRequests !== undefined) {
      let requestIndex = prevRequests.findIndex(
        (request) => request.requested_id === id
      )
      if (prevRequests.some((request) => request.requested_id === id)) {
        prevRequests.splice(requestIndex, 1)
        if (displayNameOr) prevRequests.splice(0, 0, nameRequest)
        else prevRequests.splice(0, 0, idRequest)
        database.users.doc(currentUser.uid).set(
          {
            prevRequests: prevRequests,
          },
          { merge: true }
        )
      } else {
        if (displayNameOr) prevRequests.splice(0, 0, nameRequest)
        else prevRequests.splice(0, 0, idRequest)
        database.users.doc(currentUser.uid).set(
          {
            prevRequests: prevRequests,
          },
          { merge: true }
        )
      }
    } else {
      database.users.doc(currentUser.uid).set(
        {
          prevRequests: [displayNameOr ? nameRequest : idRequest],
        },
        { merge: true }
      )
    }
  }

  async function randomGame() {
    // Existing random requests
    const randomRequests = await database.requests.where("randomRequest", "==", true).get().then(
      (snapshot) => {
        return snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            data: doc.data(),
          }
        })
      }
    )
    if (randomRequests.length === 0) {
      await database.requests.add({
        randomRequest: true,
        requested_id: null,
        requested_displayName: null,
        requested_email: null,
        requester_id: currentUser.uid,
        requester_name: userParams.displayName,
        requester_email: currentUser.email,
      })
      setShowNotification(true)
      setNotification({
        data: {
          title: `Searching for games...`,
          body: "Searching!",
          userId: currentUser.uid,
          errorType: "success",
          animationLength: "3s",
        }
      })
      navigate("/dashboard")
    }
    else {
      randomRequests.forEach(element => {
        if (element.data.requester_id !== currentUser.uid) {
          database.requests.doc(randomRequests[0].id).delete()
          database.games.add(
            {
              player1Id: currentUser.uid,
              player2Id: element.data.requester_id,
              player1Name: userParams.displayName,
              player2Name: element.data.requester_name,
              player1Score: 0,
              player2Score: 0,
              player1Round: 1,
              player2Round: 1,
              player1Won: false,
              player2Won: false,
              playerTurn: currentUser.uid,
              gameStarted: false,
              rounds: [],
            }
          )
          setShowNotification(true)
          setNotification({
            data: {
              title: `Found a game against ${element.data.requester_name}!`,
              body: "Found!",
              userId: currentUser.uid,
              errorType: "success",
              animationLength: "3s",
            }
          })
          const notificationContent = {
            title: `Found game against ${userParams.displayName}!`,
            body: `${userParams.displayName} playing round 1`,
            userId: element.data.requester_id,
            errorType: "success",
            animationLength: "5s",
          }
          sendNotification(notificationContent)
          navigate("/dashboard")
          return element
        }
      });
      navigate("/dashboard")

    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const playerId = displayName
    const users = await database.users
      .where("displayNameLower", "==", playerId.toLowerCase())
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          if (doc.id === currentUser.uid) {
            return false
          } else return doc.id
        })
      })
    if (users.includes(false)) {
      setShowNotification(true)
      setNotification({
        data: {
          title: `You can't play with yourself, silly!`,
          body: "No user found",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    } else if (users.length === 0) {
      setShowNotification(true)
      setNotification({
        data: {
          title: `User ${playerId} not found!`,
          body: "No user found",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    } else {
      const requests = await database.requests
        .where("requested_id", "==", users[0])
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            if (doc.data().requester_id !== currentUser.uid) {
              return doc.data()
            } else return false
          })
        })
      if (requests.every((request) => request !== false)) {
        let notificationContent
        await database.users
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (
                doc.data().displayName.toLowerCase() === playerId.toLowerCase()
              ) {
                database.requests.add({
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requested_email: doc.data().email,
                  requester_id: currentUser.uid,
                  requester_name: userParams.displayName,
                  requester_email: currentUser.email,
                  randomGame: false,
                })
                const sentNameRequest = {
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requester_name: userParams.displayName,
                }
                notificationContent = {
                  title: `${userParams.displayName} wants to play!`,
                  body: `Do you want to play with ${userParams.displayName}..?`,
                  icon: "https://firebasestorage.googleapis.com/v0/b/tictactoe-d9f5c.appspot.com/o/icon.png?alt=media&token=f9f9f9f9-f9f9-f9f9-f9f9-f9f9f9f9f9f9",
                  userId: doc.id,
                  errorType: "success",
                  animationLength: "3s",
                }
                addPrevRequest(doc.id, true, undefined, sentNameRequest)
              }
            })
            notificationContent && sendNotification(notificationContent)
            setShowNotification(true)
            setNotification({
              data: {
                title: `Invite sent to ${playerId}!`,
                body: "Invite sent!",
                errorType: "success",
                animationLength: "3s",
              }
            })
            return
          })
          .catch((err) => {
            // console.log("Error getting documents", err)
            setShowNotification(true)
            setNotification({
              data: {
                title: `User ${playerId} not found!`,
                body: "No user found",
                errorType: "error",
                animationLength: "3s",
              }
            })
            return
          })
        return
      } else
        setShowNotification(true)
      setNotification({
        data: {
          title: `You have already requested to play with ${playerId}`,
          body: "Already requested",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    }
  }

  async function invitePrev(request) {
    const playerId = request.requested_displayName
    const users = await database.users
      .where("displayNameLower", "==", playerId.toLowerCase())
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          if (doc.id === currentUser.uid) {
            return false
          } else return doc.id
        })
      })
    if (users.includes(false)) {
      setShowNotification(true)
      setNotification({
        data: {
          title: `You can't play with yourself, silly!`,
          body: "Can't play with yourself",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    } else if (users.length === 0) {
      setShowNotification(true)
      setNotification({
        data: {
          title: `User ${playerId} not found!`,
          body: "No user found",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    } else {
      let notificationContent
      const requests = await database.requests
        .where("requested_id", "==", users[0])
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            if (doc.data().requester_id !== currentUser.uid) {
              return doc.data()
            } else return false
          })
        })
      if (requests.every((request) => request !== false)) {
        await database.users
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (
                doc.data().displayName.toLowerCase() === playerId.toLowerCase()
              ) {
                database.requests.add({
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requested_email: doc.data().email,
                  requester_id: currentUser.uid,
                  requester_name: userParams.displayName,
                  requester_email: currentUser.email,
                  randomGame: false,
                })
                const sentNameRequest = {
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requester_name: userParams.displayName,
                }
                notificationContent = {
                  title: `${userParams.displayName} wants to play!`,
                  body: `Do you want to play with ${userParams.displayName}..?`,
                  userId: doc.id,
                  errorType: "success",
                  animationLength: "5s",
                }
                addPrevRequest(doc.id, true, undefined, sentNameRequest)
              }
            })
            notificationContent && sendNotification(notificationContent)
            setShowNotification(true)
            return setNotification({
              data: {
                title: `Invite sent to ${playerId}!`,
                body: "Invite sent!",
                errorType: "success",
                animationLength: "3s",
              }
            })

          })
          .catch((err) => {
            // console.log("Error getting documents", err)
            setShowNotification(true)
            return setNotification({
              data: {
                title: `User ${playerId} not found!`,
                body: "No user found",
                errorType: "error",
                animationLength: "3s",
              }
            })
          })
        return
      } else
        setShowNotification(true)
      return setNotification({
        data: {
          title: `You have already requested to play with ${playerId}`,
          body: "Already requested",
          errorType: "error",
          animationLength: "3s",
        }
      })
    }
  }
  function getCurrentUsers() {
    let content = []
    if (currentUsers && currentUsers[0]?.data?.prevRequests !== undefined) {
      content = currentUsers[0].data.prevRequests.map((request) => {
        return (
          <div className="game--items" key={request.requested_id}>
            <div
              className="games--container noSelect"
              style={{ background: "var(--root-background-color)"}}
            >
              <h1>{request.requested_displayName}</h1>

              <div className="prevRequest--button">
                <button
                  className="invite--button"
                  onClick={() => {
                    invitePrev(request)
                  }}
                >
                  <InviteLogo fill="#cacaca" style={{ width: "30px" }} />
                </button>
              </div>
            </div>
          </div>
        )
      })
    }
    return content
  }
  const prevRequestsItems = getCurrentUsers()

  return (
    <div className="play--online--container">
      <div className="play--online--inner">
        <div className='start--new--game--outer'>
          <div className="start--new--game blackText noSelect" onClick={randomGame}>
            <RandomLogo style={{
              width: "40px",
              margin: "0 30px",
              opacity: "0.75",
              minWidth: "25px",

            }} /><h1>Random opponent</h1><RandomLogo style={{
              width: "40px",
              margin: "0 30px",
              opacity: "0.75",
              minWidth: "25px",
            }} />
          </div>
        </div>
        <div className="play--online--invite">
          <h1
            className="play--online--header"
            style={{ color: theme === "dark" ? "#cacaca" : "#000" }}
          >
            Invite player:
          </h1>
          <h1
            className="play--online--header"
            style={{
              color: theme === "dark" ? "#cacaca" : "#000",
              fontSize: "1.3rem",
            }}
          >
            Your name is: {userParams.displayName}
          </h1>
          <form onSubmit={handleSubmit}>
            <div id="displayName" style={containerStyle}>
              <label htmlFor="displayNameInput" style={displayNameStyle}>
                Enter player name:
              </label>
              <input
                id="displayNameInput"
                type="text"
                onChange={(e) => handleChange(e)}
                style={displayNameInputStyle}
                onFocus={() => setDisplayNameSelected(true)}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setDisplayNameSelected(false)
                  }
                }}
                className="input--field mb-1"
                required
              />
            </div>
            <button
              type="submit"
              disabled={displayName === ""}
              className="w-100 mt-2 auth--button"
              style={{ background: displayName !== "" ? "#00d60f" : "#cecece" }}
            >
              Invite
            </button>
          </form>
        </div>

        <div className="prev--request">
          {prevRequestsItems && prevRequestsItems}
        </div>
      </div>

    </div>
  )
}
