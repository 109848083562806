import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"
import { getMessaging, getToken, isSupported } from "firebase/messaging"

// console.log(`${process.env.REACT_APP_API_KEY}`)

const app = firebase.initializeApp({
  // apiKey: `${process.env.REACT_APP_API_KEY}`,

  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,

  // projectId: process.env.REACT_APP_PROJECT_ID,

  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,

  // messagingSenderId: process.env.REACT_APP_MESSEGING_SENDER_ID,

  // appId: process.env.REACT_APP_APP_ID

  apiKey: "AIzaSyDaaDdt2d4lb7vl6Fn5eJ8AbETY1W0t6io",

  authDomain: "quiz-development-34445.firebaseapp.com",

  projectId: "quiz-development-34445",

  storageBucket: "quiz-development-34445.appspot.com",

  messagingSenderId: "345811281663",

  appId: "1:345811281663:web:caba364b3eadc40bd98eca",
})

const firestore = app.firestore()

export const database = {
  games: firestore.collection("games"),
  users: firestore.collection("users"),
  requests: firestore.collection("requests"),
}

export const auth = app.auth()
export const messaging = isSupported() ? getMessaging() : null
// export const messaging = (async () => {
//   try {
//     const isSupportedBrowser = await isSupported()
//     if (isSupportedBrowser) {
//       return getMessaging()
//     }
//     console.log("FCM not supported this browser")
//     return null
//   } catch (err) {
//     console.log(err)
//     return null
//   }
// })()
export const functions = getFunctions(app)
export default app

export const token = async () => {
  if(!messaging) return
  // console.error(auth.currentUser, typeof messaging)
  if (auth.currentUser) {
    let currentToken = ""
    currentToken = await getToken(messaging, {
      vapidKey:
        "BK-WmLdQBl9vbr5C6RssmjgBvYKp1g8aROTeVm_ZEker1cTAyHr1ZO9CDkH0epUftQBXShZNM3dg0R_3CjLD-7g",
    })
      .then((token) => {
        if (token && auth.currentUser) {
          // console.log("[firebase-messaging-sw.js] Token:", token)
          database.users
            .doc(auth.currentUser.uid)
            .get()
            .then((doc) => {
              if (
                doc
                  .data()
                  .FCMToken.every((existingToken) => existingToken !== token)
              ) {
                const FCMToken = doc.data().FCMToken
                FCMToken.push(token)
                database.users.doc(auth.currentUser.uid).update({
                  FCMToken,
                })
                const subscribeUser = httpsCallable(functions, "subscribeUser")
                subscribeUser({
                  FCMToken,
                  uid: auth.currentUser.uid,
                }).then((result) => {
                  // console.log(
                  //   "[firebase-messaging-sw.js] subscribeUser:",
                  //   result
                  // )
                })
              }
            })
          return token
        } else {
          // Show permission request UI
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // )
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err)
        // ...
      })

    return currentToken
  }
  return null
}
