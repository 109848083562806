import React, { useContext, useEffect } from 'react'
import { FormatContext } from '../context/FormatContext.js'

export default function GameHeader({ goToMain, catTitle }) {
    
    const { theme } = useContext(FormatContext)

    useEffect(() => {
        if(theme === 'dark') {
            document.getElementById("header").style.backgroundColor = 'rgb(35, 35, 39)'
        }
        else {
            document.getElementById("header").style.backgroundColor = 'rgb(254, 255, 248)'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='header' id='header'>
            <img alt='back-button' onClick={goToMain} id="back--button" src={theme === 'dark' ? require('../img/back-button-light.png') : require('../img/back-button.png')} />
            <h1 className={theme === 'dark' ? 'category--title light--text' : 'category--title'}>{catTitle}</h1>
            {/* <h1 className={
                            theme === 'dark' ?
                                'logo light--text noSelect'
                                :
                                'logo noSelect'}
                        >
                            .quiz
                        </h1> */}

        </div>
    )
}
