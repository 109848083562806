import React, { useRef, useState, useContext } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { FormatContext } from '../../context/FormatContext'


export default function Signup({ setNotification, setShowNotification }) {
    const displayNameRef = useRef(null)
    const emailRef = useRef(null)
    const passwordRef = useRef(null)
    const passwordConfirmRef = useRef(null)
    const { signup } = useAuth()
    const { theme, format } = useContext(FormatContext)
    const [loading, setLoading] = useState(false)
    const [displayNameSelected, setDisplayNameSelected] = useState(false)
    const [emailSelected, setEmailSelected] = useState(false)
    const [passwordSelected, setPasswordSelected] = useState(false)
    const [passwordConfirmSelected, setPasswordConfirmSelected] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()
        const displayName = displayNameRef.current.value
        const email = emailRef.current.value
        const password = passwordRef.current.value
        const passwordConfirm = passwordConfirmRef.current.value
        if (password !== passwordConfirm) {
            setShowNotification(true)
            return setNotification({
                data: {
                    title: `Passwords do not match`,
                    body: "Error",
                    errorType: "danger",
                    animationLength: "3s",
                }
            })
        }

        try {
            setLoading(true)
            if (displayName.length < 4) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be at least 4 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                setLoading(false)
                return
            }
            else if (displayName.length > 12) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be no more than 12 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                setLoading(false)
                return
            }
            await signup(email, password, displayName, false)
            navigate('/')
        }
        catch (error) {
            if (error.message === "Firebase: The email address is already in use by another account. (auth/email-already-in-use).") {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Email already in use`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
            else if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Password needs to be at least 6 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
            else if (error.message === "Firebase: The email address is badly formatted. (auth/invalid-email).") {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Email is badly formatted, check for typos`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
            else {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `${error.message}`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
            }
        }
        setLoading(false)
    }
    const displayNameStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: displayNameSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const emailStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: emailSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const passwordStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: passwordSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const passwordConfirmStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: passwordConfirmSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const displayNameBorderStyle = {
        animation: displayNameSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const emailBorderStyle = {
        animation: emailSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const passwordBorderStyle = {
        animation: passwordSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const passwordConfirmBorderStyle = {
        animation: passwordConfirmSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const darkMode = {
        background: theme === "dark" ? "#2f2f2f" : "#FFFFFF",
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
        caretColor: theme === "dark" ? "#cacaca" : "#2f2f2f",
        animation: (format === 1 || format === 2) ? "authFadeIn 0.3s ease-in-out forwards" : "none",
    }

    return (
        <>
            <div className="signup--card" style={darkMode} onClick={(e) => e.stopPropagation()}>
                <div className="signup--card--header" style={darkMode}>
                    <h1 className="signup--card--header--title">Sign up</h1>
                </div>
                <div className="signup--card-body" style={darkMode}>
                    <form onSubmit={handleSubmit}>
                        <div id="displayName">
                            <label htmlFor="displayNameInput" style={displayNameStyle}>Enter display name</label>
                            <input id="displayNameInput" type="text" ref={displayNameRef} style={displayNameBorderStyle} onFocus={() => setDisplayNameSelected(true)} onBlur={(e) => { if (e.target.value === '') { setDisplayNameSelected(false) } }} className="input--field mb-1" required />
                        </div>
                        <div id="email">
                            <label style={emailStyle}>Enter email</label>
                            <input type="email" ref={emailRef} style={emailBorderStyle} onFocus={() => setEmailSelected(true)} onBlur={(e) => { if (e.target.value === '') { setEmailSelected(false) } }} className="input--field mb-1" required />
                        </div>
                        <div id="password">
                            <label style={passwordStyle}>Enter password</label>
                            <input type="password" className="input--field mb-1" style={passwordBorderStyle} onFocus={() => setPasswordSelected(true)} onBlur={(e) => { if (e.target.value === '') { setPasswordSelected(false) } }} ref={passwordRef} required />
                        </div>
                        <div id="passwordConfirmation">
                            <label style={passwordConfirmStyle}>Confirm password</label>
                            <input type="password" className="input--field mb-1" style={passwordConfirmBorderStyle} onFocus={() => setPasswordConfirmSelected(true)} onBlur={(e) => { if (e.target.value === '') { setPasswordConfirmSelected(false) } }} ref={passwordConfirmRef} required />
                        </div>
                        <button type="submit" disabled={loading} className="w-100 mt-2 auth--button">Sign up</button>
                        <button type="button" disabled={loading} className="w-100 mt-2 auth--button" style={{ background: "#4f4f4f", color: "#f1f1f1" }} onClick={() => navigate('/auth/signup/guest')}>Log in as guest</button>
                    </form>
                </div>
                <div className="signup--card--footer">
                    Already have an account? <Link to={'/auth/login'}>Log in</Link>
                </div>
            </div>
        </>
    )
}
