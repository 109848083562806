import React, { useContext, useEffect, useState } from 'react'
import Knapp from './Knapp'
import { CategoryContext } from '../context/CategoryContext'
import { FormatContext } from '../context/FormatContext.js'
import { useAuth } from '../context/AuthContext'
import categoriesArray from '../categories.json'
import BottomNav from './BottomNav'
import { useLocation } from 'react-router-dom'

export default function ChooseCategory() {
    const { category, setCategory } = useContext(CategoryContext)
    const [mouseIsDown, setMouseIsDown] = useState(false)
    const [subIsSelectedId, setSubIsSelectedId] = useState(null)
    const [isSelectedId, setIsSelectedId] = useState(null)
    const [showSelected, setShowSelected] = useState(false)
    const [isMainSelected, setIsMainSelected] = useState(false)
    const [animateMain, setAnimateMain] = useState(false)
    const [animateSub, setAnimateSub] = useState(null)
    const { loggedIn } = useAuth()
    const { theme } = useContext(FormatContext)
    const location = useLocation()
    const { requestID, opponent, id: gameID, category: stateCategory } = location?.state || {}
    const local = location?.state?.local
    const [categories] = useState(categoriesArray)

    useEffect(() => {
        if (stateCategory) {
            setCategory(stateCategory)
        }
    }
        , [stateCategory, setCategory])

    let handleMousedown = (event) => {
        setMouseIsDown(true)
    };

    let handleMouseup = (event) => {
        setMouseIsDown(false)
    };

    document.addEventListener('mousedown', handleMousedown);
    document.addEventListener('mouseup', handleMouseup);

    function handleCategorySelection(id, url, title, short) {
        // setStoredCategory(title)
        if (id !== 1 && id !== 0) {
            if (isSelectedId === id) {
                setIsSelectedId(null)
                setSubIsSelectedId(null)
                setIsMainSelected(false)
                setCategory(({
                    title: title,
                    id: id
                }))
            }
            else {
                setAnimateMain(true)
                setAnimateSub(id)
                setIsSelectedId(id)
                setSubIsSelectedId(null)
                setShowSelected(id)
                setIsMainSelected(true)
                setCategory(({
                    title: title,
                    url: url,
                    short: short,
                    id: id
                }))
            }
        }
        else if (isSelectedId === id) {
            setIsSelectedId(null)
            setSubIsSelectedId(null)
            setShowSelected(null)
            setIsMainSelected(false)
            setCategory(({
                title: title,
                url: url,
                short: short,
                id: id
            }))
        }
        else if (id === 0) {
            setIsSelectedId(null)
            setSubIsSelectedId(null)
            setAnimateMain(false)
            setAnimateSub(null)
            return
        }
        else {
            setIsSelectedId(id)
            setSubIsSelectedId(null)
            setShowSelected(id)
            setIsMainSelected(true)
            setCategory(({
                title: title,
                url: url,
                short: short,
                id: id
            }))
        }
    }

    return (
        <>
            <div className='category--container noScroll'
                onClick={() => handleCategorySelection(0, '', '', '')}
            >
                {loggedIn && !local && opponent && category === null && <h1 className='choose--category--header' style={{ color: theme === "dark" ? "#cacaca" : "#2f2f2f" }}>Choose the category to play against {opponent}</h1>}
                {category && !local && <h1 className='choose--category--header' style={{ color: theme === "dark" ? "#cacaca" : "#2f2f2f" }}>
                    Press
                    <span style={{ color: "rgb(0, 255, 102)" }}> play</span> to play the category <span style={{ color: "rgb(193, 127, 1)" }}>{category.title.charAt(0).toLowerCase() + category.title.slice(1)}</span>
                </h1>}
                {loggedIn && opponent && category && <h1 className='choose--category--header' style={{ color: theme === "dark" ? "#cacaca" : "#2f2f2f", paddingTop: "0" }}> against {opponent}</h1>}
                {local && category === null && <h1 className='choose--category--header' style={{ color: theme === "dark" ? "#cacaca" : "#2f2f2f" }}>Choose the category to play</h1>}
                {local && category && <h1 className='choose--category--header' style={{ color: theme === "dark" ? "#cacaca" : "#2f2f2f" }}>
                    Press
                    <span style={{ color: "rgb(0, 255, 102)" }}> play</span> to play the category <span style={{ color: "rgb(193, 127, 1)" }}>{category.title.charAt(0).toLowerCase() + category.title.slice(1)}</span>
                </h1>}
                <div
                    className={theme === 'dark' ? 'start--categories--container dark--overlay' : 'start--categories--container light--overlay--dirty--code'}
                    id={animateMain ? 'mainAnimationDown' : 'mainAnimationUp'}
                >
                    {
                        categories.map((obj, index) => {
                            return (
                                <Knapp
                                    {...obj}
                                    key={`Knapp ${index}`}
                                    handleCategorySelection={handleCategorySelection}
                                    isSelectedId={isSelectedId}
                                    setIsSelectedId={setIsSelectedId}
                                    subIsSelectedId={subIsSelectedId}
                                    setSubIsSelectedId={setSubIsSelectedId}
                                    showSelected={showSelected}
                                    setShowSelected={setShowSelected}
                                    animateSub={animateSub}
                                    setAnimateSub={setAnimateSub}
                                    setAnimateMain={setAnimateMain}
                                    mouseIsDown={mouseIsDown}
                                    setMouseIsDown={setMouseIsDown}
                                    isMainSelected={isMainSelected}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <BottomNav green={true} back={true} local={loggedIn && opponent ? false : true} gameStarted={loggedIn && !local ? true : false} gameID={gameID} requestID={requestID} category={category} />
        </>
    )
}