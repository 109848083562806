import React from "react";

export default function SubKnapp(props) {
    return (
        <div
            id={props.style}
            // onMouseEnter={(event) => {format !== 1 && props.hoverSelect(props.id, props.category, props.url)}}
            // onMouseUp={(event) => {format !== 1 && event.stopPropagation(); props.handleSubCategorySelection(props.id, props.url, props.category, props.short)}}
            onClick={(event) => {event.stopPropagation(); props.handleSubCategorySelection(props.id, props.url, props.category, props.short)}}
            className={props.subIsSelectedId === props.id ? 'sub--categories sub--categories--selected noSelect' : 'sub--categories noSelect'}>

            {props.category}
        </div>
    )
}