import React, { useContext, useState } from "react";
import SubKnapp from "./SubKnapp";
import { useLocalStorage } from '../context/AuthContext'
import { CategoryContext } from '../context/CategoryContext'

export default function Knapp(props) {
    const hold = <h1 className="initial--hold">(hold)</h1>
    const release = <h1 className="initial--hold">(release)</h1>

    const [holdOrRelease, setHoldOrRelease] = useState('hold')
    // const [animateSubPrev, setAnimateSubPrev] = useState(null)
    const [mainCategory, setMainCategory] = useState(initialTitle())
    //eslint-disable-next-line no-unused-vars
    const [storedCategory, setStoredCategory] = useLocalStorage('category', '')

    const { setCategory } = useContext(CategoryContext)
    // const { format } = useContext(FormatContext)
    const [initialLoad, setInitialLoad] = useState(true)
    

    function initialTitle() {
        return props.category
    }

    function handleSubCategorySelection(id, url, title, short) {
        props.setAnimateSub(id)
        props.setAnimateMain(false)
        props.setShowSelected(props.id)
        props.setIsSelectedId(null)
        props.setSubIsSelectedId(id)
        setMainCategory(title)
        setHoldOrRelease('hold')
        setStoredCategory(title)
        setCategory(({
            title: title,
            url: url,
            short: short,
            id: id
        }))

    }

    function styleSelect() {
        return props.id === props.showSelected
    }

    function hoverSelect(id, title, url) {
        if (props.mouseIsDown) {
            setHoldOrRelease('release')
            props.setSubIsSelectedId(id)
            setMainCategory(title)
            setCategory(({
                title: title,
                url: url,
                id: id
            }))
        }
        else {
            props.setSubIsSelectedId(null)
        }

    }

    function handleClick(event) {
        setInitialLoad(false)
        if (props.id === 1) {
            event.stopPropagation()
            props.handleCategorySelection(props.id, props.url, props.category, props.short)
        }
        else if (props.id !== 1) {
            event.stopPropagation()
            props.handleCategorySelection(props.id, props.url, props.category, props.short)
            props.setAnimateMain(true)
            props.setAnimateSub(props.id)
        }
    }

    // function showSub() {
    //     if (format === 1)
    //         return true
    //     else if (format === 0 && props.isSelectedId === props.id && props.isSelectedId !== 1)
    //         return true
    //     else return false
    // }

    return (
        <>
            {/* {showSub() && */}

            <div
                className={props.animateSub === props.id ? 'sub--categories--container' : 'sub--categories--container other--selected'}
                style={{
                    animation: initialLoad ? 'none' :
                        props.animateSub === props.id ?
                            'sub-category-reveal 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards' :
                            'sub-category-hide 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards, other-cat-selected 500ms cubic-bezier(0.215, 0.610, 0.355, 1) forwards'
                }}
            >
                
                {props.subCategories.map((sub, index) => {
                    return (
                        <SubKnapp
                            key={`Subknapp ${index}`}
                            {...sub}
                            handleSubCategorySelection={handleSubCategorySelection}
                            subIsSelectedId={props.subIsSelectedId}
                            hoverSelect={hoverSelect}
                        />
                    )

                })}
            </div>
            {/* } */}
            <div className={props.style}>
                <div

                    // onMouseDown={() => { format !== 1 && props.id !== 1 && props.handleCategorySelection(props.id, props.url, props.category) }}
                    // onMouseUp={() => { format !== 1 && props.id !== 1 && props.handleCategorySelection(props.id, props.url, props.category, props.short) }}
                    onClick={(event) => handleClick(event)}
                    className=
                    {styleSelect() ?
                        `main--categories main--categories--selected noSelect ${props.style}`
                        :
                        props.isMainSelected ?
                            `main--categories other--main--selected noSelect ${props.style}`
                            :
                            `main--categories noSelect ${props.style}`
                    }
                >

                    {mainCategory}{holdOrRelease === 'hold' ? hold : release}


                </div>
            </div>
        </>

    )
}