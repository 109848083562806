import React from 'react'

export default function ReactNotification({ title, type, animationLength, setShowNotification }) {
    if (type === "error") {
        type = "danger"
    }

    return (
        <div className="notification--container" onClick={() => setShowNotification(false)} onAnimationEnd={() => setShowNotification(false)}>
            <div className={`alert notification alert-${type}`} role={type} style={{ "animation": `notificationSlideIn ${animationLength} ease-in-out forwards` }}>
                {title}
            </div>
        </div>
    )
}
