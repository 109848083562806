import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import { database } from '../../firebase'
import { useAuth, useLocalStorage } from '../../context/AuthContext'
import BottomNav from '../BottomNav'
import { ReactComponent as LockedLogo } from '../../img/lock-solid.svg'
import { ReactComponent as Crown } from '../../img/crown-solid.svg'
import { FormatContext } from '../../context/FormatContext'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { sendNotification } from "../util/sendNotification"

export default function InspectGame({ setNotification, setShowNotification }) {

  const location = useLocation()
  const gameID = location.state?.id
  const { currentUser, userParams } = useAuth()
  // const [gameID] = useLocalStorage('gameID', '')
  // eslint-disable-next-line no-unused-vars
  // const [category, setCategory] = useLocalStorage('category', '')
  const [yourTurn, setYourTurn] = useLocalStorage('yourTurn', false)
  const [showModal, setShowModal] = useState(false)
  const [currentGame, setCurrentGame] = useState(null)
  const [currentUsers, setCurrentUsers] = useState(null)
  const [wonGameModal, setWonGameModal] = useState(null)
  const { theme } = useContext(FormatContext)
  const content = generateContent()
  const { id: paramID } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // console.log(paramID)
    if (paramID) {
      navigate("/inspectgame", { state: { id: paramID } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramID])

  useEffect(() => {
    if (currentGame) {
      setWonGameModal(generateModal())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame])

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  useEffect(() => {

    function unsubscribe() {
      database.games
        .onSnapshot(snapshot => {
          snapshot.forEach(doc => {
            if (doc.id === gameID) {
              setCurrentGame(doc.data())

            }
            return
          })
        })
    }
    return unsubscribe()
  }, [gameID])

  useEffect(() => {

    function unsubscribe() {
      database.users
        .onSnapshot(snapshot => {
          const users = []
          snapshot.forEach(doc => {
            if (doc.id === currentUser.uid) {
              users.push({
                id: doc.id,
                data: doc.data()
              })
            }
          })
          setCurrentUsers(users)
        })
    }
    return unsubscribe()
  }, [currentUser.uid])

  useEffect(() => {
    if (currentGame) {
      // const index = currentGame.rounds.length - 1
      // setCategory(currentGame.rounds[index].category)
      if (currentGame.playerTurn === currentUser.uid && (currentGame.player1Won === false && currentGame.player2Won === false)) {
        setYourTurn(true)
      }
      else {
        setYourTurn(false)
      }
    }

  }, [currentGame, currentUser.uid, setYourTurn])


  function generateContent() {
    if (currentGame) {
      let player1 = []
      let player2 = []
      let category = []
      let roundSection = []
      const player1Id = currentGame.player1Id
      const player2Id = currentGame.player2Id
      for (let index = 0; index < 6; index++) {
        const element = currentGame.rounds[index];

        let roundContentPlayer1 = []
        let roundContentPlayer2 = []
        if (element) {
          category.push(element.category.title)
          element.questions.forEach((question, index) => {
            if (player1Id) {
              if (question.player1Answer === question.question.correctAns && question.player1Answer !== "") {
                roundContentPlayer1.push(
                  <div className={`answer--boxes sideBox${index} box--correct`} key={`player1-${question.question.id}`}></div>
                )
              } else if (question.player1Answer !== "" && question.player1Answer !== question.question.correctAns) {
                roundContentPlayer1.push(
                  <div className={`answer--boxes sideBox${index} box--incorrect`} key={`player1-${question.question.id}`}></div>
                )
              }
              else {
                roundContentPlayer1.push(
                  <div className={`answer--boxes sideBox${index} box--default`} key={`player1-${question.question.id}`}></div>
                )
              }
            }
            if (player2Id) {
              if (question.player2Answer === question.question.correctAns && question.player2Answer !== "") {
                roundContentPlayer2.push(
                  <div className={`answer--boxes sideBox${index} box--correct`} key={`player2-${question.question.id}`}></div>
                )
              } else if (question.player2Answer !== "" && question.player2Answer !== question.question.correctAns) {
                roundContentPlayer2.push(
                  <div className={`answer--boxes sideBox${index} box--incorrect`} key={`player2-${question.question.id}`}></div>
                )
              }
              else {
                roundContentPlayer2.push(
                  <div className={`answer--boxes sideBox${index} box--default`} key={`player2-${question.question.id}`}></div>
                )
              }
            }
          })
        }
        if ((roundContentPlayer1.length > 0 && (currentUser.uid === player1Id)) || (currentUser.uid === player2Id && currentGame.player2Round > index + 1) || (currentGame.player1Won || currentGame.player2Won)) {
          player1.push(
            <div className='round' id={`round${index + 1}`} key={`player1-round-index-${index}`}>
              {roundContentPlayer1}
            </div>
          )
        }
        else if (currentGame.gameStarted === true && currentUser.uid === player2Id && currentGame.player2Round === index + 1 && currentGame.playerTurn === currentUser.uid && (currentGame.player1Won === false && currentGame.player2Won === false)) {
          player1.push(
            <div className='round' id={`round${index + 1}`} key={`player1-round-index-${index}`}>
              <div className='answer--boxes box--locked'><LockedLogo style={{ height: "70%" }} /></div>
            </div>
          )
        }
        else {
          player1.push(
            <div className='round' id={`round${index + 1}`} key={`player2-round-index-${index}`}>
              <div className='answer--boxes box--default'></div>
            </div>
          )
        }
        if ((roundContentPlayer2.length > 0 && (currentUser.uid === player2Id)) || (currentUser.uid === player1Id && currentGame.player1Round > index + 1) || (currentGame.player1Won || currentGame.player2Won)) {
          player2.push(
            <div className='round' id={`round${index + 1}`} key={`player2-round-index-${index}`}>
              {roundContentPlayer2}
            </div>
          )
        }
        else if (currentGame.gameStarted === true && currentUser.uid === player1Id && currentGame.player1Round === index + 1 && currentGame.playerTurn === currentUser.uid && (currentGame.player1Won === false && currentGame.player2Won === false)) {
          player2.push(
            <div className='round' id={`round${index + 1}`} key={`player2-round-index-${index}`}>
              <div className='answer--boxes box--locked'><LockedLogo style={{ height: "70%" }} /></div>
            </div>
          )
        }
        else {
          player2.push(
            <div className='round' id={`round${index + 1}`} key={`player2-round-index-${index}`}>
              <div className='answer--boxes box--default'></div>
            </div>
          )
        }
        if (category[index]) {
          roundSection.push(
            <div className="roundSection" key={`round ${index}`} id={`round${index + 1}`}><p>Round {index + 1}</p><p className='category--p'>{category[index]}</p></div>
          )
        }
        else if (category[index] === undefined) {
          roundSection.push(
            <div className="roundSection" key={`round ${index}`} id={`round${index + 1}`}><p>Round {index + 1}</p></div>
          )
        }
      }
      return (

        <div className='game--content' onClick={() => showModal && setShowModal(false)}>
          <div className='players--container'>
            <div className='win--container'>
              <div className='win--container--left'>
                {(currentGame.player1Won || currentGame.player2Won) && <Crown style={{ width: "20%", minWidth: "40px", gridColumn: "1/2", gridRow: "1/2", margin: "auto" }} 
                fill={(currentGame.player1Id === currentUser.uid && currentGame.player1Won) || (currentGame.player2Id === currentUser.uid && currentGame.player2Won) ? "rgb(193, 127, 1)" : "transparent"} />}
                <h1>{currentGame.player1Id === currentUser.uid ? currentGame.player1Score : currentGame.player2Score}</h1>
              </div>
              <div className='win--container--middle'></div>
              <div className='win--container--right'>
                {((currentGame.player1Won || currentGame.player2Won)) && <Crown style={{ width: "20%", minWidth: "40px", gridColumn: "3/4", gridRow: "1/2", margin: "auto" }} 
                fill={(currentGame.player1Id === currentUser.uid && currentGame.player2Won) || (currentGame.player2Id === currentUser.uid && currentGame.player1Won) ? "rgb(193, 127, 1)" : "transparent"} />}
              <h1>{currentGame.player1Id === currentUser.uid ? currentGame.player2Score : currentGame.player1Score}</h1>
              </div>
            </div>
            <div >
              <h1 className='vs'>VS</h1>
              <h1 className='player--title'>{currentGame.player1Id === currentUser.uid ? currentGame.player2Name : currentGame.player1Name}</h1>
            </div>

          </div>
          {/* <div className='rounds--container'>
            <div className='game--left'>

              <h1>{currentGame.player1Id === currentUser.uid ? currentGame.player1Score : currentGame.player2Score}</h1>
              {currentGame.player1Id === currentUser.uid ? player1 : player2}
            </div>
            <div className='game--middle'>
              <h1>.</h1>
              {roundSection}
            </div>
            <div className='game--right'>
              <h1>{currentGame.player1Id === currentUser.uid ? currentGame.player2Score : currentGame.player1Score}</h1>
              {currentGame.player1Id === currentUser.uid ? player2 : player1}
            </div>
          </div> */}
          {roundSection.map((round, index) => {
            return (
              <div className='round--row' key={`round--row--${index}`}>
                <div className='game--left'>
                  {currentGame.player1Id === currentUser.uid ? player1[index] : player2[index]}
                </div>
                <div className='game--middle'>
                  {round}
                </div>
                <div className='game--right'>
                  {currentGame.player1Id === currentUser.uid ? player2[index] : player1[index]}
                </div>
              </div>
            )
          })
          }
        </div>

      )
    }
    else return <h1>Loading...</h1>
  }


  async function addPrevRequest(id, displayNameOr, idRequest, nameRequest) {

    const prevUserData = currentUsers[0].data
    const prevRequests = prevUserData.prevRequests
    if (prevRequests !== undefined) {
      let requestIndex = prevRequests.findIndex(request => request.requested_id === id)
      if (prevRequests.some(request => request.requested_id === id)) {
        prevRequests.splice(requestIndex, 1)
        if (displayNameOr) prevRequests.splice(0, 0, nameRequest)
        else prevRequests.splice(0, 0, idRequest)
        database.users.doc(currentUser.uid).set({
          prevRequests: prevRequests
        }, { merge: true })
      }
      else {
        if (displayNameOr) prevRequests.splice(0, 0, nameRequest)
        else prevRequests.splice(0, 0, idRequest)
        database.users.doc(currentUser.uid).set({
          prevRequests: prevRequests
        }, { merge: true })
      }
    }
    else {
      database.users.doc(currentUser.uid).set({
        prevRequests: [displayNameOr ? nameRequest : idRequest]
      }, { merge: true })
    }
  }

  async function sendRematchRequest(game) {
    const playerId = game.player1Id === currentUser.uid ? game.player2Name : game.player1Name
    const users = await database.users.where("displayNameLower", "==", playerId.toLowerCase()).get().then(snapshot => {
      return snapshot.docs.map(doc => {
        if (doc.id === currentUser.uid) {
          return false
        }
        else return doc.id
      })
    })
    if (users.includes(false)) {
      setShowNotification(true)
      return setNotification({
        data: {
          title: `You can't play with yourself, silly!`,
          body: "No user found",
          errorType: "error",
          animationLength: "3s",
        }
      })
    }
    else if (users.length === 0) {
      setShowNotification(true)
      setNotification({
        data: {
          title: `User ${playerId} not found!`,
          body: "No user found",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return
    }
    else {
      const requests = await database.requests.where("requested_id", "==", users[0])
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            if (doc.data().requester_id !== currentUser.uid) {
              return doc.data()
            }
            else return false
          })
        })
      if (requests.every(request => request !== false)) {
        let notificationContent
        await database.users
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              if (doc.id === playerId) {
                database.requests.add({
                  requested_id: playerId,
                  requested_displayName: doc.data().displayName,
                  requested_email: doc.data().email,
                  requester_id: currentUser.uid,
                  requester_name: userParams.displayName,
                  requester_email: currentUser.email,
                  randomGame: false,
                })
                const sentIdRequest = {
                  requested_id: playerId,
                  requested_displayName: doc.data().displayName,
                  requester_name: userParams.displayName,
                }
                notificationContent = {
                  title: `${userParams.displayName} wants to play!`,
                  body: `Do you want to play with ${userParams.displayName}..?`,
                  userId: playerId,
                  errorType: "success",
                  animationLength: "3s",
                }
                addPrevRequest(playerId, false, sentIdRequest, undefined)
              }
              else if (doc.data().displayName.toLowerCase() === playerId.toLowerCase()) {
                database.requests.add({
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requested_email: doc.data().email,
                  requester_id: currentUser.uid,
                  requester_name: userParams.displayName,
                  requester_email: currentUser.email,
                  randomGame: false,
                })
                const sentNameRequest = {
                  requested_id: doc.id,
                  requested_displayName: doc.data().displayName,
                  requester_name: userParams.displayName,
                }
                notificationContent = {
                  title: `${userParams.displayName} wants to play!`,
                  body: `Do you want to play with ${userParams.displayName}..?`,
                  icon: "https://firebasestorage.googleapis.com/v0/b/tictactoe-d9f5c.appspot.com/o/icon.png?alt=media&token=f9f9f9f9-f9f9-f9f9-f9f9-f9f9f9f9f9f9",
                  userId: doc.id,
                  errorType: "success",
                  animationLength: "3s",
                }
                addPrevRequest(doc.id, true, undefined, sentNameRequest)
              }
              setShowModal(false)
            })
            notificationContent && sendNotification(notificationContent)
            setShowNotification(true)
            return setNotification({
              data: {
                title: `Invite sent to ${playerId}!`,
                body: "Invite sent!",
                errorType: "success",
                animationLength: "3s",
              }
            })
          })
          .catch(err => {
            setShowNotification(true)
            setNotification({
              data: {
                title: `User ${playerId} not found!`,
                body: "No user found",
                errorType: "error",
                animationLength: "3s",
              }
            })
            return
          })
        return
      }
      else setShowNotification(true)
      setNotification({
        data: {
          title: `You have alreadddy requested to play with ${playerId}`,
          body: "Already requested",
          errorType: "error",
          animationLength: "3s",
        }
      })
      return

    }
  }

  const modalStyle = {
    background: theme === "dark" ? "#2f2f2f" : "#cacaca",
    color: theme === "dark" ? "#cacaca" : "#2f2f2f",
  }

  function generateModal() {
    let modal = []
    if (currentGame) {
      const player1Playing = currentGame.player1Id === currentUser.uid ? true : false
      const opponent = player1Playing ? currentGame.player2Name : currentGame.player1Name
      if (currentGame.player1Won === true || currentGame.player2Won === true) setShowModal(true)
      let content
      if (currentGame.player1Won === true && currentGame.player2Won === true) {
        content = <h1 className='modal--body'>Draw!</h1>
      }
      else if ((currentGame.player1Won === true && currentGame.player1Id === currentUser.uid) || (currentGame.player2Won === true && currentGame.player2Id === currentUser.uid)) {
        content = <h1 className='modal--body'>You won against {opponent}!</h1>
      }
      else if (currentGame.player1Won === true && currentGame.player2Id === currentUser.uid) {
        content = <h1 className='modal--body'>{currentGame.player1Name} won!</h1>
      }
      else if (currentGame.player2Won === true && currentGame.player1Id === currentUser.uid) {
        content = <h1 className='modal--body'>{currentGame.player2Name} won!</h1>
      }

      modal = (
        <div className='modal--container' style={modalStyle}>
          <div className='modal--content'>
            <h1 className='modal--title'>Game Over</h1>
            {content}
            <button className='modal--button' onClick={() => { sendRematchRequest(currentGame) }}>Rematch</button>
          </div>
        </div>
      )
      return modal
    }
  }

  return (
    <>
      {/* <Header /> */}
      {/* {error && <div className="error--container">{error}</div>} */}
      {showModal && wonGameModal}
      {content}
      <BottomNav green={yourTurn} back={true} disabled={!yourTurn} gameStarted={currentGame?.gameStarted} />
    </>
  )

}
