import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { database } from '../../firebase'

export default function ChooseCategoryRoute({ children }) {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const location = useLocation()
    const gameID = location.state?.id
    const requestID = location.state?.requestID
    const local = location.state?.local

    async function getGameData() {
        if (gameID) {
            const game = await database.games.doc(gameID).get().then(doc => doc.data())
            if(!game) return
            const playerRound = game.player1Id === currentUser.uid ? game.player1Round : game.player2Round
            
            if (game !== undefined && game.playerTurn === currentUser.uid) {
                if(playerRound > game.rounds.length) {
                    return
                }
                else if (game.rounds[playerRound-1].category === undefined && playerRound === game.rounds.length && game.rounds[playerRound-1]?.questions.every(question => {
                    if (game.player1Id === currentUser.uid) {
                        return (question.player1Answer === "")
                    }
                    else if (game.player2Id === currentUser.uid) {
                        return (question.player2Answer === "")
                    }
                    else return null
                })) {
                    return
                }
                else {
                    navigate('/game/online', { state: { id: gameID } })
                }
            }
        }
        else if(!gameID && !currentUser) {
            return 
        }
        else if (gameID === undefined && currentUser !== undefined && requestID !== undefined) {
            return 
        }
        else if (local) {
            return 
        }
        else return navigate('/dashboard')
    }
    React.useEffect(() => {
        getGameData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [])
    return children
}