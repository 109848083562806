import React, { useContext, useEffect, useState } from 'react'
import { FormatContext } from '../context/FormatContext'
import { ReactComponent as DiscordLogo } from '../img/discord-brands.svg'

export default function AboutMe() {
    const { theme, format } = useContext(FormatContext)
    const [currentlyOpened, setCurrentlyOpened] = useState(null)

    useEffect(() => {
        if (format === 0) {
            document.body.style.setProperty('--pulse-color', 'none')
            document.body.style.setProperty('--pulse-accent-color', 'none')
            document.getElementById('root').setAttribute('style', 'overflow: visible !important')
        }
        return () => {
            document.body.style.setProperty('--pulse-color', '#fafafa')
            document.body.style.setProperty('--pulse-accent-color', '#ffffff')
            document.getElementById('root').setAttribute('style', 'overflow: hidden')

        }
    }, [format])

    function showModal(modal) {
        const modalQuery = document.querySelectorAll(modal)
        modalQuery.forEach(modal => {
            modal.style.display = "block";
            modal.style.animation = 'showModal 0.1s ease-in-out forwards'
        })
    }

    function hideModal(modal) {
        const modalQuery = document.querySelectorAll(modal)
        modalQuery.forEach(modal => {
            modal.style.animation = 'hideModal 0.1s ease-in-out forwards'
        })
    }

    function showMobileModal(modal) {
        const element = document.querySelector(`.popup--modal${currentlyOpened}`)
        const modalQuery = document.querySelectorAll(`.popup--modal${modal}`)
        const anchor = document.querySelector(`${modal}-anchor`)

        if (currentlyOpened !== null && modal === null) {
            element.style.animation = 'hideMobileModal 0.2s ease-out forwards'
            setCurrentlyOpened(null)
        }
        if (currentlyOpened === modal) {
            setCurrentlyOpened(null)
            modalQuery.forEach(modal => {
                modal.style.animation = 'hideMobileModal 0.2s ease-in-out forwards'
            })
            return
        }
        else if (currentlyOpened) {
            element.style.animation = 'hideMobileModal 0.2s ease-out forwards'
            setCurrentlyOpened(modal)
            setTimeout(() => {
                modalQuery.forEach(modall => {
                    modall.style.display = "block";
                    modall.style.animation = 'showMobileModal 0.5s ease-out forwards'
                    return anchor.scrollIntoView({ behavior: "smooth", block: "start" })

                })
                return
            }, 200)
        }
        else {
            setCurrentlyOpened(modal)
            modalQuery.forEach(modall => {
                modall.style.display = "block";
                modall.style.animation = 'showMobileModal 0.5s ease-out forwards'
                return anchor.scrollIntoView({ behavior: "smooth", block: "start" })

            })
        }


    }

    function handleAnimationEnd(e, modal) {
        if (e.animationName === 'hideMobileModal' || e.animationName === 'hideModal') {
            const modalQuery = document.querySelectorAll(modal)
            modalQuery.forEach(modal => {
                modal.style.display = 'none'
            })
        }
    }

    return (
        <div className='general--container' onClick={() => showMobileModal(null)}>
            <div className='about-me--container'>
                <div className='about-me--text'>
                    <h1 style={{ color: theme === "dark" ? "rgb(131, 0, 221)" : "rgb(74, 0, 125)" }}>Hi there!</h1>
                    <h3>I'm Pontus, a self-taught <span style={{ color: "rgb(193, 127, 1)" }}>full stack developer</span> from Sweden</h3>
                    <p><span style={{ backgroundColor: "rgb(0,0,0, 0.6)" }}>I started this project in May after completing an entry level course in React, and it ended in me learning </span> <span style={{ fontWeight: "900", fontSize: "110%", backgroundColor: "rgb(0,0,0, 0.8)", marginLeft: "5px", padding: "0 4px" }}>TONS</span> <span style={{ backgroundColor: "rgb(0,0,0, 0.6)" }}>in these months I've been building .quiz up from a simple quiz game, to what it is today.</span></p>
                </div>
                {/* <img className='myself' src={require('../img/benim.png')} /> */}
            </div>
            <div className='competence--container'>
                <h3>But we both know what you're <span style={{ fontWeight: "900", fontSize: "110%", color: "rgb(193, 127, 1)" }}>really</span> interested in, so here is a breakdown of what I have learned so far: </h3>
                <div className='competence--container--inner'>
                    {format === 0 &&
                        <>
                            <div className='competence--row topRow'>
                                <div className='competence--item' id="react" onMouseEnter={() => showModal(".react")} onMouseLeave={() => hideModal(".react")} onAnimationEnd={(e) => handleAnimationEnd(e, ".react")}>
                                    <img src={require('../img/react.png')} alt='react' />
                                    <h3>React</h3>
                                    <div className="popup--modal react" style={{ width: "1070px", left: "0" }}>
                                        <p style={{ paddingBottom: "0"}}>Where it all started. I would say that I'm now somewhat of a React fiend. 😈</p>
                                        <p>Jokes aside, I do have a great understanding of the framework, and I'm baffled by how many helper libraries there are; as well as how well thought-out it is! It's not the best performing language, however, but for smaller projects it's just perfect.</p>
                                    </div>
                                    <div className='popup--modal--arrow react'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".html")} onMouseLeave={() => hideModal(".html")} onAnimationEnd={(e) => handleAnimationEnd(e, ".html")}>
                                    <img src={require('../img/html.png')} alt='html' />
                                    <h3>HTML</h3>
                                    <div className="popup--modal html" >
                                        <p>This is somewhat of a no-brainer, but it's worth mentioning, since it's the mother-language!</p>
                                    </div>
                                    <div className='popup--modal--arrow html'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".css")} onMouseLeave={() => hideModal(".css")} onAnimationEnd={(e) => handleAnimationEnd(e, ".css")}>
                                    <img src={require('../img/css.png')} alt='css' />
                                    <h3>CSS/Sass</h3>
                                    <div className="popup--modal css" style={{ width: "1070px" }}>
                                        <p>Some might say they dislike CSS, but once you understand the inner workings, it really is a fine language to use. As you can probably see, I'm no designer; but I make do with what I have! Worth mentioning is that everything you see, including the dark mode switch, game-functionality/animations, as well as the big red button is made by me using pure css.</p>
                                    </div>
                                    <div className='popup--modal--arrow css'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".typescript")} onMouseLeave={() => hideModal(".typescript")} onAnimationEnd={(e) => handleAnimationEnd(e, ".typescript")}>
                                    <img src={require('../img/typescript.png')} alt='typescript' />
                                    <h3>TypeScript</h3>
                                    <div className="popup--modal typescript">
                                        <p>TypeScript messes with you, but for good reasons. While this project is not built in TS, I have used it in a bunch of smaller projects.</p>
                                    </div>
                                    <div className='popup--modal--arrow typescript'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".javascript")} onMouseLeave={() => hideModal(".javascript")} onAnimationEnd={(e) => handleAnimationEnd(e, ".javascript")}>
                                    <img src={require('../img/javascript.png')} alt='javascript' />
                                    <h3>JavaScript</h3>
                                    <div className="popup--modal javascript">
                                        <p>While vanilla JS is not essential when using React to build projects, I still like using JS for things that React simply can't handle yet.</p>
                                    </div>
                                    <div className='popup--modal--arrow javascript'></div>
                                </div>
                            </div>
                            <div className='competence--row bottomRow'>
                                <div className='competence--item' onMouseEnter={() => showModal(".firebase")} onMouseLeave={() => hideModal(".firebase")} onAnimationEnd={(e) => handleAnimationEnd(e, ".firebase")}>
                                    <img src={require('../img/firebase.png')} alt='firebase' />
                                    <h3>Firebase</h3>
                                    <div className="popup--modal firebase" style={{ width: "1070px", left: "0" }}>
                                        <p>Ah Firebase.. How you have made my life both easy and painful all at once. It was, however, a good introduction to the full-stack experience! It is also relatively scaleable, which meant that I could just keep building on functions on this app. (in the beginning, I had no intention to add authentication and online capabilities, but had to take on the challenge)</p>
                                    </div>
                                    <div className='popup--modal--arrow firebase'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".node")} onMouseLeave={() => hideModal(".node")} onAnimationEnd={(e) => handleAnimationEnd(e, ".node")}>
                                    <img src={require('../img/node.png')} alt='node' />
                                    <h3>Node.js</h3>
                                    <div className="popup--modal node">
                                        <p>This project is built on Firebase using their Cloud Functions, but I took an extensive course in Node anyways. Can't hurt, right?</p>
                                    </div>
                                    <div className='popup--modal--arrow node'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".nextjs")} onMouseLeave={() => hideModal(".nextjs")} onAnimationEnd={(e) => handleAnimationEnd(e, ".nextjs")}>
                                    <img src={require('../img/nextjs.png')} alt='nextjs' />
                                    <h3>Next.js</h3>
                                    <div className="popup--modal nextjs">
                                        <p>I've taken an extensive course in Next, and I can really see the benefits of using it when dealing with a larger-scale project. Next is now my preferred language to use when creating my projects, and I would love to work in it!</p>
                                    </div>
                                    <div className='popup--modal--arrow nextjs'></div>
                                </div>

                                <div className='competence--item' onMouseEnter={() => showModal(".git")} onMouseLeave={() => hideModal(".git")} onAnimationEnd={(e) => handleAnimationEnd(e, ".git")}>
                                    <img src={require('../img/git.png')} alt='git' />
                                    <h3>Git</h3>
                                    <div className="popup--modal git">
                                        <p>Source control is important, and it has saved my rear-end several times.</p>
                                    </div>
                                    <div className='popup--modal--arrow git'></div>
                                </div>
                                <div className='competence--item' onMouseEnter={() => showModal(".bootstrap")} onMouseLeave={() => hideModal(".bootstrap")} onAnimationEnd={(e) => handleAnimationEnd(e, ".bootstrap")}>
                                    <img src={require('../img/bootstrap.png')} alt='bootstrap' />
                                    <h3>Bootstrap</h3>
                                    <div className="popup--modal bootstrap">
                                        <p>I'm not terribly confident with using bootstrap as I usually like to design my own components, but it definitely is a really good alternative for making quick, clean designs!</p>
                                    </div>
                                    <div className='popup--modal--arrow bootstrap'></div>
                                </div>
                            </div>

                        </>
                    }
                    {format === 1 &&
                        <>
                            <div className='competence--row firstRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' id='react' onClick={(e) => {
                                        e.stopPropagation();
                                        showMobileModal(".react");
                                        document.body.style.setProperty('--pulse-color', 'none')
                                        document.body.style.setProperty('--pulse-accent-color', 'none')
                                    }} onAnimationEnd={(e) => handleAnimationEnd(e, ".react")}>
                                        <div className="react-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/react.png')} alt='react' />
                                        <h3>React</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".html") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".html")}>
                                        <div className="html-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/html.png')} alt='html' />
                                        <h3>HTML</h3>
                                    </div>
                                </div>

                                <div className="popup--modal react">
                                    {/* <div className="react-anchor" style={{position: "absolute", top: "-85px"}}></div> */}
                                    <h3>React</h3>
                                    <p style={{ paddingBottom: "0"}}>Where it all started. I would say that I'm now somewhat of a React fiend. 😈</p>
                                    <p>Jokes aside, I do have a great understanding of the framework, and I'm baffled by how many helper libraries there are; as well as how well thought-out it is! It's not the best performing language, however, but for smaller projects it's just perfect.</p>
                                </div>
                                <div className="popup--modal html">
                                    <h3>HTML</h3>
                                    <p>This is somewhat of a no-brainer, but it's worth mentioning, since it's the mother-language!</p>
                                </div>

                            </div>
                            <div className='competence--row secondRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".css") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".css")}>
                                        <div className="css-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/css.png')} alt='css' />
                                        <h3>CSS/Sass</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".typescript") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".typescript")}>
                                        <div className="typescript-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/typescript.png')} alt='typescript' />
                                        <h3>TypeScript</h3>
                                    </div>
                                </div>

                                <div className="popup--modal css">
                                    <h3>CSS/Sass</h3>
                                    <p>Some might say they dislike CSS, but once you understand the inner workings, it really is a fine language to use. As you can probably see, I'm no designer; but I make do with what I have! Worth mentioning is that everything you see, including the dark mode switch, game-functionality/animations, as well as the big red button is made by me using pure css.</p>
                                </div>
                                <div className="popup--modal typescript">
                                    <h3>TypeScript</h3>
                                    <p>TypeScript messes with you, but for good reasons. While this project is not built in TS, I have used it in a bunch of smaller projects.</p>
                                </div>
                            </div>
                            <div className='competence--row thirdRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".javascript") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".javascript")}>
                                        <div className="javascript-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/javascript.png')} alt='javascript' />
                                        <h3>JavaScript</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".firebase") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".firebase")}>
                                        <div className="firebase-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/firebase.png')} alt='firebase' />
                                        <h3>Firebase</h3>
                                    </div>
                                </div>

                                <div className="popup--modal javascript">
                                    <h3>JavaScript</h3>
                                    <p>While vanilla JS is not essential when using React to build projects, I still like using JS for things that React simply can't handle yet.</p>
                                </div>
                                <div className="popup--modal firebase">
                                    <h3>Firebase</h3>
                                    <p>Ah Firebase.. How you have made my life both easy and painful all at once. It was, however, a good introduction to the full-stack experience! It is also relatively scaleable, which meant that I could just keep building on functions on this app. (in the beginning, I had no intention to add authentication and online capabilities, but had to take on the challenge)</p>
                                </div>
                            </div>
                            <div className='competence--row fourthRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".node") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".node")}>
                                        <div className="node-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/node.png')} alt='node' />
                                        <h3>Node.js</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".nextjs") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".nextjs")}>
                                        <div className="nextjs-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/nextjs.png')} alt='nextjs' />
                                        <h3>Next.js</h3>
                                    </div>
                                </div>

                                <div className="popup--modal node">
                                    <h3>Node.js</h3>
                                    <p>This project is built on Firebase using their Cloud Functions, but I took an extensive course in Node anyways. Can't hurt, right?</p>
                                </div>
                                <div className="popup--modal nextjs">
                                    <h3>Next.js</h3>
                                    <p>I've taken an extensive course in Next, and I can really see the benefits of using it when dealing with a larger-scale project. Next is now my preferred language to use when creating my projects, and I would love to work in it!</p>
                                </div>
                            </div>
                            <div className='competence--row fifthRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' id='git' onClick={(e) => { e.stopPropagation(); showMobileModal(".git") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".git")}>
                                        <div className="git-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/git.png')} alt='git' />
                                        <h3>Git</h3>
                                    </div>
                                    <div className='competence--item' id='bootstrap' onClick={(e) => { e.stopPropagation(); showMobileModal(".bootstrap") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".bootstrap")}>
                                        <div className="bootstrap-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/bootstrap.png')} alt='bootstrap' />
                                        <h3>Bootstrap</h3>
                                    </div>
                                </div>

                                <div className="popup--modal git">
                                    <h3>Git</h3>
                                    <p>Source control is important, and it has saved my rear-end several times.</p>
                                </div>
                                <div className="popup--modal bootstrap">
                                    <h3>Bootstrap</h3>
                                    <p>I'm not terribly confident with using bootstrap as I usually like to design my own components, but it definitely is a really good alternative for making quick, clean designs!</p>
                                </div>
                            </div>
                        </>
                    }
                    {format === 2 &&
                        <>

                            <div className='competence--row firstRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' id='react' onClick={(e) => {
                                        e.stopPropagation();
                                        showMobileModal(".react")
                                        document.body.style.setProperty('--pulse-color', 'none')
                                        document.body.style.setProperty('--pulse-accent-color', 'none')
                                    }} onAnimationEnd={(e) => handleAnimationEnd(e, ".react")}>
                                        <div className="react-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/react.png')} alt='react' />
                                        <h3>React</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".html") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".html")}>
                                        <div className="html-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/html.png')} alt='html' />
                                        <h3>HTML</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".css") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".css")}>
                                        <div className="css-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/css.png')} alt='css' />
                                        <h3>CSS/Sass</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".typescript") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".typescript")}>
                                        <div className="typescript-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/typescript.png')} alt='typescript' />
                                        <h3>TypeScript</h3>
                                    </div>
                                </div>
                                <div className="popup--modal react">
                                    <h3>React</h3>
                                    <p style={{ paddingBottom: "0"}}>Where it all started. I would say that I'm now somewhat of a React fiend. 😈</p>
                                    <p>Jokes aside, I do have a great understanding of the framework, and I'm baffled by how many helper libraries there are; as well as how well thought-out it is! It's not the best performing language, however, but for smaller projects it's just perfect.</p>
                                </div>
                                <div className="popup--modal html" >
                                    <h3>HTML</h3>
                                    <p>This is somewhat of a no-brainer, but it's worth mentioning, since it's the mother-language!</p>
                                </div>
                                <div className="popup--modal css">
                                    <h3>CSS/Sass</h3>
                                    <p>Some might say they dislike CSS, but once you understand the inner workings, it really is a fine language to use. As you can probably see, I'm no designer; but I make do with what I have! Worth mentioning is that everything you see, including the dark mode switch, game-functionality/animations, as well as the big red button is made by me using pure css.</p>
                                </div>
                                <div className="popup--modal typescript">
                                    <h3>TypeScript</h3>
                                    <p>TypeScript messes with you, but for good reasons. While this project is not built in TS, I have used it in a bunch of smaller projects.</p>
                                </div>

                            </div>
                            <div className='competence--row secondRow'>
                                <div className='competence--button'>


                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".javascript") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".javascript")}>
                                        <div className="javascript-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/javascript.png')} alt='javascript' />
                                        <h3>JavaScript</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".firebase") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".firebase")}>
                                        <div className="firebase-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/firebase.png')} alt='firebase' />
                                        <h3>Firebase</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".node") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".node")}>
                                        <div className="node-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/node.png')} alt='node' />
                                        <h3>Node.js</h3>
                                    </div>
                                    <div className='competence--item' onClick={(e) => { e.stopPropagation(); showMobileModal(".nextjs") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".nextjs")}>
                                        <div className="nextjs-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/nextjs.png')} alt='nextjs' />
                                        <h3>Next.js</h3>
                                    </div>
                                </div>


                                <div className="popup--modal javascript">
                                    <h3>JavaScript</h3>
                                    <p>While vanilla JS is not essential when using React to build projects, I still like using JS for things that React simply can't handle yet.</p>
                                </div>
                                <div className="popup--modal firebase">
                                    <h3>Firebase</h3>
                                    <p>Ah Firebase.. How you have made my life both easy and painful all at once. It was, however, a good introduction to the full-stack experience! It is also relatively scaleable, which meant that I could just keep building on functions on this app. (in the beginning, I had no intention to add authentication and online capabilities, but had to take on the challenge)</p>
                                </div>
                                <div className="popup--modal node">
                                    <h3>Node.js</h3>
                                    <p>This project is built on Firebase using their Cloud Functions, but I took an extensive course in Node anyways. Can't hurt, right?</p>
                                </div>
                                <div className="popup--modal nextjs">
                                    <h3>Next.js</h3>
                                    <p>I've taken an extensive course in Next, and I can really see the benefits of using it when dealing with a larger-scale project. Next is now my preferred language to use when creating my projects, and I would love to work in it!</p>
                                </div>
                            </div>
                            <div className='competence--row thirdRow'>
                                <div className='competence--button'>
                                    <div className='competence--item' id='git' onClick={(e) => { e.stopPropagation(); showMobileModal(".git") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".git")}>
                                        <div className="git-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/git.png')} alt='git' />
                                        <h3>Git</h3>
                                    </div>
                                    <div className='competence--item' id='bootstrap' onClick={(e) => { e.stopPropagation(); showMobileModal(".bootstrap") }} onAnimationEnd={(e) => handleAnimationEnd(e, ".bootstrap")}>
                                        <div className="bootstrap-anchor" style={{ position: "absolute", top: "-85px" }}></div>
                                        <img src={require('../img/bootstrap.png')} alt='bootstrap' />
                                        <h3>Bootstrap</h3>
                                    </div>
                                </div>
                                <div className="popup--modal git">
                                    <h3>Git</h3>
                                    <p>Source control is important, and it has saved my rear-end several times.</p>
                                </div>
                                <div className="popup--modal bootstrap">
                                    <h3>Bootstrap</h3>
                                    <p>I'm not terribly confident with using bootstrap as I usually like to design my own components, but it definitely is a really good alternative for making quick, clean designs!</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className='personal--info'>
                <h3 style={{ color: "rgb(193, 127, 1)" }}>Now that you know what my merits are, let's get to know the man himself!</h3>
                <p style={{ marginBottom: "0" }}>
                    My name is Pontus Thelin, a 25-year-old swede with a newly-revived passion for coding.
                    It wasn't until in March this year, when my friend told me about React, that I decided to take a leave of abscence from work to study coding for a while.
                    <br></br><br></br>I did do some web development, C++ and C# coding in secondary high school, but I got a job at AstraZeneca close to home, and that's where I worked for 5 years until I started studying.

                    It paid well, but as I didn't have the free time to study React, so I decided to take the plunge and start coding again.
                </p>
                <div style={{ width: "100%", height: "2px", boxShadow: "2px 2px 2px rgb(0, 0, 0)", margin: "30px 0" }}></div>
                <h5 style={{ color: theme === "dark" ? "rgb(219, 75, 255)" : "rgb(74, 0, 125)" }}>So why give up the salary and live off your savings/stocks in an emerging economical depression?</h5>
                <p>
                    <span style={{ fontStyle: "italic" }}>Short answer: I've always been a fast learner, and I've gotten tired of knowing everything there is to know at my job.</span>
                </p>
                <p style={{ marginBottom: "0" }}>
                    <br></br>
                    And really, it's about my insatiable desire to learn more. I know it sounds cliché, but this project, for example, has taken me way further than I'd ever thought possible due to me just wanting to improve it to learn more.
                    Of course, this kind of mentality would not fit well in all company settings considering deadlines that have to be met, but just know that I'm never shy to learn and adapt!
                </p>
                <div style={{ width: "100%", height: "2px", boxShadow: "2px 2px 2px rgb(0, 0, 0)", margin: "30px 0" }}></div>
                <h4>Aside from the professional aspects I also love music production, preferrably techno 😈</h4>
                <p style={{ marginBottom: "0" }}>
                    I've been creating music on and off for quite a while; even had my own studio in the past. Now, there isn't much time to make music, but on some rare occasions I'll sit down and make some <span style={{ fontWeight: "900", fontSize: "110%" }}>BANGERS!</span><br></br>
                    Be sure to hit me up if you want to hear some of it!
                </p>
                <div style={{ width: "100%", height: "2px", boxShadow: "2px 2px 2px rgb(0, 0, 0)", margin: "30px 0" }}></div>
                <h4>I hope this summary of who I am has given you some insights into what I'm capable of, and I sure hope you enjoy the app!</h4>
                <div style={{ display: "flex"}}>
                    <h6>If you have any questions, thoughts, or bug-reports, you can reach me via
                    <span
                        onMouseEnter={() => showModal(".discord")}
                        onMouseLeave={() => hideModal(".discord")}
                        style={{ color: "#5662f6", position: "relative", cursor: "pointer" }}
                        onClick={() => window.open("https://discordapp.com/users/95785100888772608", "_blank")}
                    > Discord
                        <div className="popup--modal discord"
                            onClick={() => window.open("https://discordapp.com/users/95785100888772608", "_blank")}
                        >
                            <DiscordLogo style={{ width: "40px", height: "40px" }} fill={"#5662f6"} />
                            <p style={{ padding: "5px" }}>Technoponta#8269</p>
                        </div>
                        <div className='popup--modal--arrow discord'></div></span>,
                    <span onClick={() => window.open("https://www.linkedin.com/in/pontus-thelin-440639139/", "_blank")}
                        style={{ color: "#0a66c2", cursor: "pointer" }}> LinkedIn
                    </span> or <a style={{ textDecoration: "none" }}
                        href="mailto: pontus.thelin@hotmail.com?subject=I%20saw%20your%20app%20and%20I%27m%20really%20impressed%21">email</a>!
                </h6>
                </div>
            </div>
        </div>
    )
}
