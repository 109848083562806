import React, { useRef, useState, useContext } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import randomWords from 'random-words'
import { FormatContext } from '../../context/FormatContext'


export default function SignupGuest({ setNotification, setShowNotification }) {
    const displayNameRef = useRef('')
    const [displayName, setDisplayName] = useState('')
    const { signup } = useAuth()
    const { theme, format } = useContext(FormatContext)
    const [loading, setLoading] = useState(false)
    const [displayNameSelected, setDisplayNameSelected] = useState(false)
    const navigate = useNavigate()

    function handleChange(e) {
        setDisplayName(e.target.value)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const displayName = displayNameRef.current.value
        const email = randomWords() + '@' + randomWords() + '.com'
        const password = randomWords({
            exactly: 3, join: '', maxLength: 5, formatter: (word) => {
                return word.slice(0, 1).toUpperCase().concat(word.slice(1))
            }
        })
        
        try {
            setLoading(true)
            if(displayName.length < 4) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be at least 4 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                setLoading(false)
                return
            }
            else if(displayName.length > 12) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be no more than 12 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                setLoading(false)
                return
            }
            await signup(email, password, displayName, true)
            navigate('/dashboard')
        } catch (error) {
            setShowNotification(true)
                setNotification({
                    data: {
                        title: `Failed to create guest account, try again`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
        }
        setLoading(false)
    }

    const displayNameStyle = {
        transform: "translateY(25px) translatex(2px)",
        animation: displayNameSelected ? 'labelFadeOutOfInput 0.15s ease-in-out forwards' : 'labelFadeIntoInput 0.15s ease-in-out forwards',
    }

    const displayNameBorderStyle = {
        animation: displayNameSelected ? 'enlargeBorder 0.15s ease-in-out forwards' : 'smollerBorder 0.15s ease-in-out forwards',
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
    }

    const darkMode = {
        background: theme === "dark" ? "#2f2f2f" : "#FFFFFF",
        color: theme === "dark" ? "#cacaca" : "#2f2f2f",
        caretColor: theme === "dark" ? "#cacaca" : "#2f2f2f",
        animation: (format === 1 || format === 2) ? "authFadeIn 0.3s ease-in-out forwards" : "none",
    }

    return (
        <>
            <div className="signup--card" style={darkMode} onClick={(e) => e.stopPropagation()}>
                    <div className="signup--card--header" style={darkMode}>
                        <h1 className="signup--card--header--title">Log in as guest</h1>
                    </div>
                    <div className="signup--card-body" style={darkMode}>
                        <form onSubmit={handleSubmit} className="signup--card-form">
                            <div id="displayName">
                                <label htmlFor="displayNameInput" style={displayNameStyle}>Enter temporary display name</label>
                                <input id="displayNameInput" type="text" ref={displayNameRef} onChange={(e) => handleChange(e)} style={displayNameBorderStyle} onFocus={() => setDisplayNameSelected(true)} onBlur={(e) => {if(e.target.value === ''){setDisplayNameSelected(false)}}} className="input--field mb-1" required />
                            </div>
                            <button type="submit" disabled={loading || displayName === ''} className="w-100 mt-2 auth--button" style={{background: displayName !== '' ? "#00d60f" : "#cecece"}}>Create guest account</button>
                        </form>
                    </div>
                    <div className="signup--card--footer">
                        Already have an account? <Link to={'/auth/login'}>Log in</Link>
                    </div>
                </div>
        </>
    )
}
