import React, { useContext, useEffect, useState } from 'react'
import { useLocalStorage } from '../../context/AuthContext'
import ActiveGames from './ActiveGames'
import BottomNav from '../BottomNav'
import { CategoryContext } from '../../context/CategoryContext'


export default function Dashboard() {

    const [key, setKey] = useState("gameID")
    const [storedValue, setStoredValue] = useLocalStorage(key, null)
    const { setCategory } = useContext(CategoryContext)

    useEffect(() => {

        setKey("gameID")
        setStoredValue(null)
        setKey("opponent")
        setStoredValue(null)
        setKey("category")
        setStoredValue(null)
        setKey("yourTurn")
        setStoredValue(false)
        setCategory(null)

    }, [storedValue, setStoredValue, setCategory])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    

    return (
        <>  
            <ActiveGames />
            <BottomNav local={true}/>
        </>
    )

}
