import React, { useContext, useEffect } from 'react'
import { FormatContext } from '../context/FormatContext.js'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import BottomNav from './BottomNav'
import randomWords from 'random-words'
import { CategoryContext } from '../context/CategoryContext.js'

export default function StartScreen({ setShowNotification, setNotification }) {
    const { format, theme } = useContext(FormatContext)
    const { currentUser, signup } = useAuth()
    const navigate = useNavigate()
    const { setCategory } = useContext(CategoryContext)


    useEffect(() => {
        (currentUser && (window.location.pathname !== "/inspectgame" || window.location.pathname !== "/choose-category")) && navigate('/dashboard')
    }, [currentUser, navigate])

    useEffect(() => {
        setCategory(null)
    }, [setCategory])

    async function instantAccess(e) {
        e.preventDefault()
        const displayName = randomWords({
            exactly: 2, join: '', maxLength: 5, formatter: (word) => {
                return word.slice(0, 1).toUpperCase().concat(word.slice(1))
            }
        })
        const email = randomWords() + '@' + randomWords() + '.com'
        const password = randomWords({
            exactly: 3, join: '', maxLength: 5, formatter: (word) => {
                return word.slice(0, 1).toUpperCase().concat(word.slice(1))
            }
        })

        try {
            if (displayName.length < 6) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be at least 6 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                return
            }
            else if (displayName.length > 12) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be no more than 12 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                return
            }
            setShowNotification(true)
            setNotification({
                data: {
                    title: `Logging in...`,
                    body: "Loading",
                    errorType: "success",
                    animationLength: "3s",
                }
            })
            await signup(email, password, displayName, true)
            navigate('/dashboard')
        } catch (error) {
            setShowNotification(true)
            setNotification({
                data: {
                    title: `Failed to create guest account, try again`,
                    body: "Error",
                    errorType: "danger",
                    animationLength: "3s",
                }
            })
        }
    }



    return (
        <>


            <div className="welcome--container">
                <h1 className={theme === "dark" ? 'light--text' : ''}>Welcome to .quiz!</h1>
                <h2 className={theme === "dark" ? 'light--text' : ''}>Press play to try the game offline, or log in to play online!</h2>
                <p style={{ color: theme === "dark" ? "rgb(0, 255, 102)" : "rgb(0, 155, 0" }}><strong>TIP: </strong> press the big red button to try online functionality instantly!</p>
            </div>
            <div className="instant-access--container">
                {format === 0 &&
                    <div className='features--button info--button' onClick={() => navigate('/features')}><h1>Features</h1></div>}
                {format === 0 &&
                    <div className='about--button info--button' onClick={() => navigate('/aboutme')}><h1>About me</h1></div>}
                <div className='info--instant--access' onClick={instantAccess}>
                    <h3 className="noSelect">Instant Access</h3>
                </div>
            </div>

            {(format === 1 || format === 2) &&
                <div className='features--button info--button' onClick={() => navigate('/features')}><h1>Features</h1></div>
            }
            {(format === 1 || format === 2) &&
                <div className='about--button info--button' onClick={() => navigate('/aboutme')}><h1>About me</h1></div>
            }
            <div className='info--text'>
                {format === 0 && <p className={theme === "dark" ? 'light--text' : ''}>
                    .quiz, created by me, <a href="https://www.linkedin.com/in/pontus-thelin-440639139/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: "rgb(193, 127, 1)" }}>
                        Pontus Thelin</a> and is made for demonstration and entertainment purposes.
                </p>}
                {(format === 1 || format === 2) && <p className={theme === "dark" ? 'light--text' : ''}>
                    .quiz | created by me | <a href="https://www.linkedin.com/in/pontus-thelin-440639139/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: "rgb(193, 127, 1)" }}>
                        Pontus Thelin</a>
                </p>}
            </div>
            <BottomNav local={true} />
            {/* {error && error} */}
            {/* {format === 0 &&
                <>
                    {currentUser ?
                        <div className='login--button' onClick={(e) => {
                            e.stopPropagation()
                            handleLogOut(e)
                            navigate('/')
                        }}>
                            Log out
                        </div>
                        :
                        <div className='login--button' onClick={(e) => {
                            e.stopPropagation()
                            navigate('/login')
                        }}>
                            Log in
                        </div>}
                    {loggedIn && <ActiveGames />}
                    <div
                        className={theme === 'dark' ? 'start--categories--container dark--overlay firstColumn' : 'start--categories--container light--overlay--dirty--code firstColumn'}
                        id={animateMain ? 'mainAnimationDown' : 'mainAnimationUp'}
                    >
                        <div className={theme === 'dark' ? 'separator separator--dark firstColumn' : 'separator firstColumn'}>
                            <div className={theme === 'dark' ? 'line line--dark firstColumn' : 'line firstColumn'}></div>
                            <h2>OR</h2>
                            <div className={theme === 'dark' ? 'line line--dark firstColumn' : 'line firstColumn'}></div>
                        </div>
                        {
                            props.categories.map((obj, index) => {
                                return (
                                    <Knapp
                                        {...obj}
                                        key={`Knapp ${index}`}
                                        handleCategorySelection={handleCategorySelection}
                                        isSelectedId={isSelectedId}
                                        setIsSelectedId={setIsSelectedId}
                                        subIsSelectedId={subIsSelectedId}
                                        setSubIsSelectedId={setSubIsSelectedId}
                                        showSelected={showSelected}
                                        setShowSelected={setShowSelected}
                                        animateSub={animateSub}
                                        setAnimateSub={setAnimateSub}
                                        setAnimateMain={setAnimateMain}
                                        mouseIsDown={mouseIsDown}
                                        setMouseIsDown={setMouseIsDown}
                                        isMainSelected={isMainSelected}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className='start--button noSelect firstColumn' onClick={(e) => {
                        e.stopPropagation()
                        navigate('/game')
                    }}>
                        Start quiz
                        {format === 1 && <img alt='back-button'
                            id="start--button--arrow"
                            src={theme === 'dark' ? require('../img/back-button-light.png') : require('../img/back-button.png')} />}
                    </div>
                </>
            } */}
            <Outlet />
        </>

    )
}