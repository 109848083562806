import React, { useState, useContext, useEffect } from 'react'
import { FormatContext } from '../context/FormatContext'
import randomWords from 'random-words'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { ReactComponent as LILogo } from '../img/linkedin-in-brands.svg'
import { database } from '../firebase'

export default function Question(props, { setNotification, setShowNotification }) {

    const [timerRanOut, setTimerRanOut] = useState(false)
    const ansObj = props.answers
    const { theme, format } = useContext(FormatContext)
    const navigate = useNavigate()
    const { signup, loggedIn, currentUser } = useAuth()
    const location = useLocation()
    const local = location?.state?.local
    const [intervals, setIntervals] = useState(null)


    useEffect(() => {
        // const questionStartTime = props.questionNumber === props.questionIndex ? Date.now() : null
        if (props.questionNumber === props.questionIndex) {
            if (local && props.questionAnswered === false) {
                return startTimer(Date.now())
            }
            if (!props.currentGame) return
            if (props.questionAnswered) return
            let prevRounds = props.currentGame.rounds
            const currentQuestion = prevRounds[prevRounds.length - 1].questions[props.questionIndex]
            const player1StartTime = props.currentGame.rounds[props.currentGame.rounds.length - 1].questions[props.questionNumber].player1StartTime
            const player2StartTime = props.currentGame.rounds[props.currentGame.rounds.length - 1].questions[props.questionNumber].player2StartTime

            if (currentUser.uid === props.currentGame.player1Id && player1StartTime === null) {
                const date = Date.now()
                const alteredQuestion = { ...currentQuestion, player1StartTime: date }
                prevRounds[prevRounds.length - 1].questions[props.questionIndex] = alteredQuestion
                startTimer(date)
                database.games.doc(props.gameID).update({
                    rounds: prevRounds
                })
            }
            else if (currentUser.uid === props.currentGame.player2Id && player2StartTime === null) {
                const date = Date.now()
                const alteredQuestion = { ...currentQuestion, player2StartTime: date }
                prevRounds[prevRounds.length - 1].questions[props.questionIndex] = alteredQuestion
                startTimer(date)
                database.games.doc(props.gameID).update({
                    rounds: prevRounds
                })
            }

            else if (currentUser.uid === props.currentGame.player1Id && player1StartTime !== null) {
                startTimer(player1StartTime)
            }
            else if (currentUser.uid === props.currentGame.player2Id && player2StartTime !== null) {
                startTimer(player2StartTime)
            }

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.questionNumber, props.questionIndex, props.currentGame, props.gameID, props.questionAnswered, currentUser?.uid])

    function startTimer(time) {
        setTimerRanOut(false)
        const now = Date.now()
        const timeLeft = now - time
        if (document.getElementById(`timer${props.questionNumber}`)) {
            document.getElementById(`timer${props.questionNumber}`).style.animation = `quizCountdown 15s linear ${-timeLeft}ms forwards`
        }
        setIntervals(() => {
            const interval = setInterval(() => {
                const now = Date.now()
                const timeLeft = now - time
                const percent = timeLeft / -100 + 150
                if (percent <= 0) {
                    if (props.questionNumber === props.questionIndex) {
                        setTimerRanOut(true)
                        props.setQuestionAnswered(true)
                        chooseAnswer(null, props.questionIndex)
                    }
                    stopTimer()
                    clearInterval(interval)
                }
            }, 100)
            return interval
        })
    }

    function stopTimer() {
        clearInterval(intervals)
    }

    const isTrue = (x) => {
        return x.selected === true
    }

    function chooseAnswer(id, index) {
        if (timerRanOut === false && document.getElementById(`timer${props.questionNumber}`)) document.getElementById(`timer${props.questionNumber}`).style.animationPlayState = "paused"
        stopTimer()
        props.selection(id, props.questionId, index)
    }

    const answerButtons = props.answers.map((ans, index) => {
        let buttonTheme = theme === 'dark' ? '--dark' : '--light'
        let correctIncorrect
        if (ans.selected === true && ans.correct === false && ans.incorrect === false) {
            correctIncorrect = `answer--button answer--selected${buttonTheme} noSelect`
        }
        else if (ans.noAnswer === true && ans.incorrect === true) {
            correctIncorrect = `answer--button answer--not--selected${buttonTheme} noSelect`
        }
        else if (ans.selected === true && ans.correct === true) {
            correctIncorrect = `answer--button answer--correct${buttonTheme} noSelect`
        }
        else if (ans.selected === true && ans.incorrect === true) {
            correctIncorrect = `answer--button answer--incorrect${buttonTheme} noSelect`
        }
        else if (ans.selected === false && ans.correct === true) {
            correctIncorrect = `answer--button answer--correct${buttonTheme} user--incorrect${buttonTheme} noSelect`
        }
        else {
            correctIncorrect = `answer--button answer${buttonTheme} noSelect`
        }



        return (
            <div
                id={props.finishedGame ? `no--cursor answer${index}` : `answer${index}`}
                className={theme === 'dark' ? `${correctIncorrect} light--text answer--text--dark` : `${correctIncorrect} answer--text`}
                onClick={() => {
                    (props.finishedGame || ansObj.some(isTrue) || timerRanOut)
                        ||
                        chooseAnswer(ans.id, props.questionIndex)
                }}
                onAnimationEnd={() => { props.setQuestionAnswered(true) }}
                key={`ans ${index}`}
            >
                <div className='answer--box--inner'><h1 className='answer--text'>{ans.answer}</h1></div>
            </div>
        )
    }
    )

    async function instantAccess(e) {
        e.preventDefault()
        const displayName = randomWords({
            exactly: 3, join: '', maxLength: 5, formatter: (word) => {
                return word.slice(0, 1).toUpperCase().concat(word.slice(1))
            }
        })
        const email = randomWords() + '@' + randomWords() + '.com'
        const password = randomWords({
            exactly: 3, join: '', maxLength: 5, formatter: (word) => {
                return word.slice(0, 1).toUpperCase().concat(word.slice(1))
            }
        })

        try {
            if (displayName.length < 6) {
                setShowNotification(true)
                setNotification({
                    data: {
                        title: `Display name must be at least 6 characters`,
                        body: "Error",
                        errorType: "danger",
                        animationLength: "3s",
                    }
                })
                return
            }
            await signup(email, password, displayName, true)
            navigate('/dashboard')
        } catch (error) {
            setShowNotification(true)
            setNotification({
                data: {
                    title: `Failed to create guest account, try again`,
                    body: "Error",
                    errorType: "danger",
                    animationLength: "3s",
                }
            })
        }
    }

    return (
        <>
            {props.questionNumber === props.questionIndex ?
                <>
                    <div className={theme === 'dark' ? 'question--counter--container question--counter--container--dark' : 'question--counter--container'}>
                        <div id={`counter--1`} className={`question--counter sideBox0 ${props.userSelectedBoxes[0]}`}></div>
                        <div id={`counter--2`} className={`question--counter sideBox1 ${props.userSelectedBoxes[1]}`}></div>
                        <div id={`counter--3`} className={`question--counter sideBox2 ${props.userSelectedBoxes[2]}`}></div>
                        <div id={`counter--4`} className={`question--counter sideBox3 ${props.userSelectedBoxes[3]}`}></div>
                        <div id={`counter--5`} className={`question--counter sideBox4 ${props.userSelectedBoxes[4]}`}></div>
                    </div>
                    <div
                        className={theme === 'dark' ? 'inner--question--container inner--question--container--dark' : 'inner--question--container'}
                        onClick={props.questionAnswered ? () => { props.next(false, false); clearInterval(intervals) } : undefined}>

                        <label className={theme === 'dark' ? 'question--title question--title--dark noSelect' : 'question--title noSelect'}>{props.question}</label>
                        {(format === 1 || format === 2) && props.questionAnswered && <div className="reminder--container"><h3>Tap to go to next question</h3></div>}
                        {format === 0 && props.questionAnswered && <div className="reminder--container"><h3>Click to go to next question</h3></div>}

                        <div className='quiz--countdown--outer'>
                            <div
                                className='quiz--countdown--inner'
                                id={`timer${props.questionNumber}`}
                                style={
                                    timerRanOut ?
                                        {
                                            animationName: "timeRanOut",
                                            animationDuration: "700ms",
                                            animationPlayState: "running",
                                            animationFillMode: "forwards",
                                            animationIterationCount: "3",
                                        }
                                        :
                                        {}

                                }>
                            </div>
                        </div>
                    </div>

                    <div className={props.answers.length === 2 ? 'answer--container--true--false' : 'answer--container'}>
                        {answerButtons}
                    </div>
                </>
                :
                (props.finishedGame && props.questionIndex === 4) &&
                <>
                    <div className='correct--answer--counter'
                        onClick={() => props.next(false, false)}
                        style={{ position: "absolute", inset: "0 0 300px 0", display: 'flex', flexDirection: "column", padding: "20px", textAlign: 'center' }}>
                        <h3>You scored {props.correctAnswer}/5 points!</h3>
                        {local && !loggedIn &&
                            <>
                                <h3>Log in to play with your friends, it's much funnier!</h3>
                                <div className="instant-access--container">
                                    <div className='info--instant--access' style={{ maxWidth: "35%" }} onClick={instantAccess}>
                                        <h3 className="noSelect">Instant Access</h3>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className="about--links--container"
                        style={{ display: "flex", bottom: "0", left: "50%", transform: "translateX(-50%)" }}>
                        <div className="about--button--linkedin">
                            <a className='links--button linkedIn' href='https://www.linkedin.com/in/pontus-thelin-440639139/' rel='noreferrer' target={"_blank"}>
                                <LILogo className='nav--logo' fill={"#FFFFFF"} />
                                <h1>My LinkedIn</h1>
                            </a>
                        </div>
                        <div className="about--buttons">
                            <div className='links--button features' onClick={() => { navigate('/features') }}><h1>Features</h1></div>
                            <div className='links--button aboutme' onClick={() => { navigate('/aboutme') }}><h1>About me</h1></div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}